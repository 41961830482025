import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import ButtonIcon from './ButtonIcon';

import { SubmitButton, AnchorButton, UnstyledButton } from './Button.styled';

const Button = ({
  type,
  children,
  to,
  fluid,
  styled,
  icon,
  invert,
  theme,
  ...rest
}) => (
  <>
    {type === 'submit' && (
      <SubmitButton type="submit" fluid={fluid} invert={invert} {...rest}>
        {icon && <ButtonIcon icon={icon} fill={invert && theme.colors.blue} />}
        {children}
      </SubmitButton>
    )}
    {type === 'button' &&
      (styled ? (
        <SubmitButton type="button" fluid={fluid} invert={invert} {...rest}>
          {icon && (
            <ButtonIcon
              icon={icon}
              fill={invert ? theme.colors.blue : theme.colors.black}
            />
          )}
          {children}
        </SubmitButton>
      ) : (
        <UnstyledButton type="button" fluid={fluid} {...rest}>
          {icon && <ButtonIcon icon={icon} fill={theme.colors.black} />}
          {children}
        </UnstyledButton>
      ))}
    {type === 'a' && (
      <AnchorButton to={to} fluid={fluid} invert={invert} {...rest}>
        {icon && <ButtonIcon icon={icon} fill={invert && theme.colors.blue} />}
        {children}
      </AnchorButton>
    )}
  </>
);

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  to: PropTypes.string,
  fluid: PropTypes.bool,
  styled: PropTypes.bool,
  icon: PropTypes.string,
  invert: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(Button);
