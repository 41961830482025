import { actions } from './actions';

const INITIAL_STATE = {
  user: {},
  loginError: null,
  signUp: {
    otpSent: false,
    otpConfirmed: false,
    otpError: null,
    signUpError: null,
  },
  forgotPassword: {
    emailSent: false,
    forgotPasswordError: null,
  },
  profile: {
    profileUpdated: false,
    profileError: null,
  },
  changePassword: {
    passwordChanged: false,
    changePasswordError: null,
  },
  supplierSignUp: {
    user: {},
    supplierCities: [],
    allCities: [],
    allClients: [],
  },
  userSupplier: {},
};

export default function userReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, user: payload };
    case actions.LOGOUT:
      return { ...state, user: {} };
    case actions.SET_LOGIN_ERROR:
      return { ...state, loginError: payload };
    case actions.SIGN_UP_UPDATE:
      return { ...state, signUp: payload };
    case actions.FORGOT_PASSWORD_UPDATE:
      return { ...state, forgotPassword: payload };
    case actions.PROFILE_UPDATE:
      return { ...state, profile: payload };
    case actions.CHANGE_PASSWORD_UPDATE:
      return { ...state, changePassword: payload };
    case actions.SUPPLIER_CITIES_SUCCESS:
      return {
        ...state,
        supplierSignUp: { ...state.supplierSignUp, supplierCities: payload },
      };
    case actions.ALL_CITIES_SUCCESS:
      return {
        ...state,
        supplierSignUp: { ...state.supplierSignUp, allCities: payload },
      };
    case actions.ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        supplierSignUp: { ...state.supplierSignUp, allClients: payload },
      };
    case actions.SUPPLIER_SIGNUP_USER:
      return {
        ...state,
        supplierSignUp: { ...state.supplierSignUp, user: payload },
      };
    case actions.USER_SUPPLIER_SUCCESS:
      return { ...state, userSupplier: payload };
    default:
      return state;
  }
}
