import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  suppliersSearchRequest,
  citiesRequest,
  tagsRequest,
  partnersRequest,
} from 'modules/Listing/store/actions';
import { logout } from 'modules/Users/store/actions';

import Header from '../components/Header';

const HeaderContainer = ({
  dispatch,
  cities,
  tags,
  partners,
  user,
  searchKeyword,
  searchCities,
}) => {
  const [citiesLoadPending, setCitiesLoadPending] = useState(true);
  const [tagsLoadPending, setTagsLoadPending] = useState(true);
  const [partnersLoadPending, setPartnersLoadPending] = useState(true);
  const history = useHistory();

  function searchHandler(payload) {
    dispatch(suppliersSearchRequest(payload));
  }

  function logOut() {
    window.localStorage.removeItem('user_auth_token');
    window.localStorage.removeItem('user_auth_time');
    dispatch(logout());
    history.push('/');
  }

  useEffect(() => {
    if (cities.length === 0 && citiesLoadPending) {
      dispatch(citiesRequest());
      setCitiesLoadPending(false);
    }
  }, [cities, dispatch, setCitiesLoadPending, citiesLoadPending]);

  useEffect(() => {
    if (tags.length === 0 && tagsLoadPending) {
      dispatch(tagsRequest());
      setTagsLoadPending(false);
    }
  }, [tags, dispatch, setTagsLoadPending, tagsLoadPending]);

  useEffect(() => {
    if (partners.length === 0 && partnersLoadPending) {
      dispatch(partnersRequest());
      setPartnersLoadPending(false);
    }
  }, [partners, dispatch, setPartnersLoadPending, partnersLoadPending]);

  return (
    <Header
      cities={cities}
      tags={tags}
      searchHandler={searchHandler}
      user={user}
      logOut={logOut}
      pathname={history.location.pathname}
      isDark={
        history.location.pathname === '/' ||
        history.location.pathname.startsWith('/search')
      }
      searchDefaults={{ searchKeyword, searchCities }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = ({
  listing: {
    cities,
    tags,
    partners,
    suppliersSearch: { searchKeyword, searchCities },
  },
  user: { user },
}) => ({
  cities,
  tags,
  partners,
  user,
  searchKeyword,
  searchCities,
});

HeaderContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cities: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  searchCities: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
