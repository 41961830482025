import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import Container from 'components/Layout/Container';
import Loading from 'components/Loading';

import { Page } from './signUp.styled';

const SignupBuyer = lazy(() => import('modules/Users/container/SignupBuyer'));
const SignupSupplier = lazy(() =>
  import('modules/Users/container/SignupSupplier'),
);

const SignUpPage = ({ match, history }) => (
  <Page isSupplier={match.path === '/sign-up/supplier'}>
    <Helmet title="Sign Up | EasyProcur" />
    <Container>
      {match.path === '/sign-up' && (
        <Suspense fallback={<Loading />}>
          <SignupBuyer history={history} />
        </Suspense>
      )}
      {match.path === '/sign-up/supplier' && (
        <Suspense fallback={<Loading />}>
          <SignupSupplier />
        </Suspense>
      )}
    </Container>
  </Page>
);

SignUpPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SignUpPage;
