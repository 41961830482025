import React from 'react';
import { Helmet } from 'react-helmet';

import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';

import Main from './privacyPolicy.styled';

const PrivacyPolicyPage = () => (
  <DashboardLayout>
    <Helmet title="Privacy Policy | EasyProcur" />
    <Main>
      <Container>
        <div className="content">
          <h1>Privacy Policy</h1>
          <p>
            When you register with EasyProcur , we need to collect certain
            information to be able to process your data . What we need will be
            sought in a prescribed format which you will need to fill or
            provided assistance to fill. This information will form part of a
            permanent record of your dealings with EasyProcur.
          </p>
          <p>
            When you email us, we may ask for certain information to be able to
            check your records and answer your questions quickly and accurately.
            When you contact us, in any way, we may make a note of this contact
            if it is relevant to your permanent record. This allows us to
            provide a higher level of Customer centricity. We will keep your
            personal details, given to us by you or others during your
            relationship with us.
          </p>
          <p>These include:</p>
          <ul>
            <li>
              Details you give us on while filling up the forms to register in
              the Platform during conversations with you on the phone.
            </li>
            <li>
              Details you give us so that we can enable sellers for buyers and
              buyers for sellers You agree that we may use and update this
              centrally held information:
            </li>
            <ul>
              <li>Information to manage your accounts;</li>
              <li>to enable with all our services through this platform;</li>
              <li>
                to identify any products and services which might suit your
                business;
              </li>
              <li>to update our records about you;</li>
              <li>to check your identity.</li>
            </ul>
          </ul>
          <p>
            You agree that we may use your personal information for research and
            statistical analysis with the aim of improving our services. When
            assessing an application, we may use Automated systems for
            decision-making. The information we hold about you is confidential.
          </p>
          <p>
            You can update your company credentials or give us your consent to
            do so in order to obtain professional advice, the law permits or
            requires it, or any regulatory or governmental body requests or
            requires it, even without your consent or there is a duty to the
            public to reveal the information. We will ensure that your
            information is adequately protected and bound by our obligations as
            per the information security management system.
          </p>
          <h2>Use of cookies</h2>
          <p>
            This website uses cookies. Cookies collect information about your
            use of the website, including things like your connection speed,
            details of your operating system, the time and duration of your
            visit and your IP address. The information collected by cookies
            enables us to understand the use of our site, including the number
            of visitors we have, the pages viewed per session, time exposed to
            particular pages etc. This in turn helps us to provide you with a
            better experience, since we can evaluate the level of interest in
            the content of our website and tailor it accordingly.
          </p>
          <p>
            We will not attempt to personally identify you from your IP address
            unless required to as a matter of law or regulation. Most browsers
            automatically accept cookies. You can set your browser options so
            that you will not receive cookies and you can also delete existing
            cookies from your browser. However, you may find that some parts of
            the site will not function properly if you refuse cookies.
          </p>
          <h2>Monitoring</h2>
          <p>
            We may monitor, record, store and use any telephone, email or other
            electronic communications with you for training purposes, so that we
            can check any instructions given to us and to improve the quality of
            our customer service. We will monitor network traffic from time to
            time for the purposes of backup and problem solving.
          </p>
          <h2>Protecting your privacy</h2>
          <p>
            To the extent that sensitive personal data is processed about you,
            we will employ appropriate security measures given the nature of
            that data. However, you recognize that your use of the Internet and
            our website is entirely at your own risk. We have tried to create a
            secure and reliable website for our users. However, we have no
            responsibility or liability for the security of personal information
            transmitted via the Internet. Our website may provide links and
            banner advertisements to third party sites. Please note that we are
            not responsible for, and have no control over, information that is
            submitted to or collected by these third parties. Since we do not
            control those websites, you are responsible for reviewing and
            abiding by the privacy policies of these third party sites.
          </p>
          <h2>Accurate Information</h2>
          <p>
            We may check what you have told us and share that information with
            fraud prevention agencies. It is important that you give us accurate
            information. If you give us false or inaccurate data and we suspect
            fraud we will record this at the fraud prevention agencies.
          </p>
          <p>
            You are responsible for informing us that your personal details have
            changed: for example, a change of address or to your title. You
            confirm that you will inform us of any change to your personal
            information as soon as is reasonably practicable in order that we
            may update our records. If you feel your privacy has been breached
            by us, then please write to&nbsp;
            <a href="mailto:support@easyprocur.com">support@easyprocur.com.</a>
            &nbsp; During your time on this site you will have the opportunity
            to consent to us contacting you by e-mail, phone, post, SMS or MMS
            from time to time occasionally about our services which may be of
            interest to you. You must not use any false identity in email or
            other network communications. You must not use the services and/or
            network systems or any part thereof for fraudulent activities, or to
            breach another organization’s security (cross-network hacking). This
            is an illegal act and prosecution under criminal law may result.
          </p>
          <h2>Changes to Privacy Policy</h2>
          <p>
            Please note that this policy will be reviewed, and may change, from
            time to time. The revised policy will be posted to this page so that
            you are always aware of the information we collect, how we use it
            and under what circumstances we disclose it.
          </p>
          <h2>Your consent</h2>
          <p>
            By using this website you are consenting to this privacy policy. We
            may in the future change this policy, and if we do so we will post
            the amended policy on this website.
          </p>
        </div>
      </Container>
    </Main>
  </DashboardLayout>
);

export default PrivacyPolicyPage;
