import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/Footer';

import Header from 'modules/Header';

const DashboardLayout = ({ children, hideFooter }) => (
  <>
    <Header />
    {children}
    {!hideFooter && <Footer />}
  </>
);

DashboardLayout.propTypes = {
  children: PropTypes.node,
  hideFooter: PropTypes.bool,
};

export default DashboardLayout;
