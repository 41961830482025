import actionCreator from 'utils/actionCreator';

export const actions = actionCreator('app', [
  'LOGIN_REQUEST',
  'LOGIN_SUCCESS',
  'LOGOUT',
  'SET_LOGIN_ERROR',
  'GET_USER_DETAILS',
  'GENERATE_OTP',
  'CHECK_OTP',
  'SIGN_UP_UPDATE',
  'SIGN_UP_USER',
  'FORGOT_PASSWORD',
  'FORGOT_PASSWORD_UPDATE',
  'CHANGE_PASSWORD',
  'CHANGE_PASSWORD_UPDATE',
  'UPDATE_USER',
  'PROFILE_UPDATE',
  'SUPPLIER_CITIES_REQUEST',
  'SUPPLIER_CITIES_SUCCESS',
  'ALL_CITIES_REQUEST',
  'ALL_CITIES_SUCCESS',
  'ALL_CLIENTS_REQUEST',
  'ALL_CLIENTS_SUCCESS',
  'ADD_CLIENT_DETAIL',
  'CREATE_SUPPLIER',
  'SUPPLIER_SIGNUP_USER',
  'USER_SUPPLIER_SUCCESS',
  'UPDATE_SUPPLIER',
]);

export function loginRequest(payload) {
  return {
    type: actions.LOGIN_REQUEST,
    payload,
  };
}

export function loginSuccess(payload) {
  return {
    type: actions.LOGIN_SUCCESS,
    payload,
  };
}

export function logout() {
  return {
    type: actions.LOGOUT,
  };
}

export function setLoginError(payload) {
  return {
    type: actions.SET_LOGIN_ERROR,
    payload,
  };
}

export function getUserDetails(payload) {
  return {
    type: actions.GET_USER_DETAILS,
    payload,
  };
}

export function generateOTP(payload) {
  return {
    type: actions.GENERATE_OTP,
    payload,
  };
}

export function checkOTP(payload) {
  return {
    type: actions.CHECK_OTP,
    payload,
  };
}

export function signUpUpdate(payload) {
  return {
    type: actions.SIGN_UP_UPDATE,
    payload,
  };
}

export function signUpUser(payload) {
  return {
    type: actions.SIGN_UP_USER,
    payload,
  };
}

export function forgotPassword(payload) {
  return {
    type: actions.FORGOT_PASSWORD,
    payload,
  };
}

export function forgotPasswordUpdate(payload) {
  return {
    type: actions.FORGOT_PASSWORD_UPDATE,
    payload,
  };
}

export function changePassword(payload) {
  return {
    type: actions.CHANGE_PASSWORD,
    payload,
  };
}

export function changePasswordUpdate(payload) {
  return {
    type: actions.CHANGE_PASSWORD_UPDATE,
    payload,
  };
}

export function updateUser(payload) {
  return {
    type: actions.UPDATE_USER,
    payload,
  };
}

export function profileUpdate(payload) {
  return {
    type: actions.PROFILE_UPDATE,
    payload,
  };
}

export function supplierCitiesRequest() {
  return {
    type: actions.SUPPLIER_CITIES_REQUEST,
  };
}

export function supplierCitiesSuccess(payload) {
  return {
    type: actions.SUPPLIER_CITIES_SUCCESS,
    payload,
  };
}

export function allCitiesRequest() {
  return {
    type: actions.ALL_CITIES_REQUEST,
  };
}

export function allCitiesSuccess(payload) {
  return {
    type: actions.ALL_CITIES_SUCCESS,
    payload,
  };
}

export function allClientsRequest() {
  return {
    type: actions.ALL_CLIENTS_REQUEST,
  };
}

export function allClientsSuccess(payload) {
  return {
    type: actions.ALL_CLIENTS_SUCCESS,
    payload,
  };
}

export function addClientDetail(payload, callback) {
  return {
    type: actions.ADD_CLIENT_DETAIL,
    payload,
    callback,
  };
}

export function createSupplier(payload, callback) {
  return {
    type: actions.CREATE_SUPPLIER,
    payload,
    callback,
  };
}

export function supplierSignUpUser(payload) {
  return {
    type: actions.SUPPLIER_SIGNUP_USER,
    payload,
  };
}

export function userSupplierSuccess(payload) {
  return {
    type: actions.USER_SUPPLIER_SUCCESS,
    payload,
  };
}

export function updateSupplier(payload, callback) {
  return {
    type: actions.UPDATE_SUPPLIER,
    payload,
    callback,
  };
}
