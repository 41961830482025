import { actions } from './actions';

const INITIAL_STATE = {
  searchRecords: [],
  connectedSuppliers: [],
  supportRequestSuccess: false,
  supportRequestError: null,
  searchRecordsLoading: true,
};

export default function dashboardReducer(
  state = INITIAL_STATE,
  { type, payload },
) {
  switch (type) {
    case actions.SEARCH_RECORDS_SUCCESS:
      return { ...state, searchRecords: payload };
    case actions.SEARCH_RECORDS_LOADING:
      return { ...state, searchRecordsLoading: payload };
    case actions.CONNECTED_SUPPLIERS_SUCCESS:
      return { ...state, connectedSuppliers: payload };
    case actions.SUPPORT_REQUEST_SUCCESS:
      return { ...state, supportRequestSuccess: payload };
    case actions.SUPPORT_REQUEST_ERROR:
      return { ...state, supportRequestError: payload };
    default:
      return state;
  }
}
