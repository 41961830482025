import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Button from 'components/Button';

import { AnchorMenu } from './SupplierDetails.styled';

const DetailsAnchorMenu = ({
  isDashboard,
  clearSupplier,
  isSupplierPage,
  isListingPage,
}) => {
  const [activeAnchor, setActiveAnchor] = useState('about');
  const [container, setContainer] = useState(null);
  const [isPageScrolled, setPageScrolled] = useState(false);
  const menuRef = useRef();

  const scrollHandler = useCallback(() => {
    const menuRect = menuRef.current.getBoundingClientRect();
    const sections = ['about', 'contact', 'services', 'gallery', 'more'];
    const sectionsEl = sections.map((i) => document.getElementById(i));
    const sectionsRect = sectionsEl.map((i) =>
      i ? i.getBoundingClientRect() : null,
    );
    let active = 'about';

    sectionsRect.forEach((i, index) => {
      if (i && i.top < menuRect.top + menuRect.height + 30)
        active = sections[index];
    });

    setActiveAnchor(active);
    setPageScrolled(window.pageYOffset > 20);
  }, [menuRef]);

  function anchorClickHandler(e) {
    e.preventDefault();

    const el = document.querySelector(
      e.target.href.substring(e.target.href.indexOf('#')),
    );

    if (el) {
      if (isDashboard) {
        const modal = document.getElementById(
          'dashboard-supplier-details-modal',
        );

        modal.scrollTo({
          top: el.offsetTop - 70,
          left: 0,
          behavior: 'smooth',
        });
      } else
        window.scrollTo({
          top: el.offsetTop - 70,
          left: 0,
          behavior: 'smooth',
        });
    }
  }

  function selectChangeHandler(e) {
    const el = document.querySelector(`#${e.target.value}`);

    setActiveAnchor(e.target.value);

    if (container)
      container.parentElement.scrollTo({
        top: el.offsetTop - 50,
        left: 0,
        behavior: 'smooth',
      });
  }

  useEffect(() => {
    setContainer(document.getElementById('supplier-details-container'));
  }, []);

  useEffect(() => {
    if (isDashboard && window.innerWidth > 767) {
      const modal = document.getElementById('dashboard-supplier-details-modal');

      modal.addEventListener('scroll', scrollHandler);
      return () => modal.removeEventListener('scroll', scrollHandler);
    }

    if (window.innerWidth > 767)
      window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler, isDashboard]);

  useEffect(() => {
    let el = null;

    if (container) el = container.parentElement;
    if (el) el.addEventListener('scroll', scrollHandler);

    return () => {
      if (el) el.removeEventListener('scroll', scrollHandler);
    };
  }, [container, scrollHandler]);

  return (
    <>
      <AnchorMenu
        isListingPage={isListingPage}
        isDashboard={isDashboard}
        isSupplierPage={isSupplierPage}
        style={
          isListingPage && isPageScrolled === false
            ? { transform: 'translateY(40px)' }
            : {}
        }>
        <ul ref={menuRef}>
          <li>
            <a
              href="#about"
              onClick={anchorClickHandler}
              className={activeAnchor === 'about' ? 'active' : ''}>
              About
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={anchorClickHandler}
              className={activeAnchor === 'contact' ? 'active' : ''}>
              Contact
            </a>
          </li>
          <li>
            <a
              href="#services"
              onClick={anchorClickHandler}
              className={activeAnchor === 'services' ? 'active' : ''}>
              Services
            </a>
          </li>
          <li>
            <a
              href="#gallery"
              onClick={anchorClickHandler}
              className={activeAnchor === 'gallery' ? 'active' : ''}>
              Gallery
            </a>
          </li>
          <li>
            <a
              href="#more"
              onClick={anchorClickHandler}
              className={activeAnchor === 'more' ? 'active' : ''}>
              More Details
            </a>
          </li>
        </ul>
        <div className="anchor-dropdown">
          <select
            name="anchor-menu"
            id="anchor-menu"
            value={activeAnchor}
            onChange={selectChangeHandler}>
            <option value="about">About</option>
            <option value="contact">Contact</option>
            <option value="services">Services</option>
            <option value="gallery">Gallery</option>
            <option value="more">More</option>
          </select>
          <Icon
            name="chevron"
            width="12px"
            height="12px"
            verticalAlign="middle"
            rotate="-90deg"
          />
        </div>
        {!isSupplierPage && (
          <Button
            type="button"
            className="clear-button"
            onClick={clearSupplier}>
            <Icon
              name="close"
              width="20px"
              height="20px"
              verticalAlign="middle"
            />
          </Button>
        )}
      </AnchorMenu>
    </>
  );
};

DetailsAnchorMenu.propTypes = {
  clearSupplier: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool.isRequired,
  isSupplierPage: PropTypes.bool.isRequired,
  isListingPage: PropTypes.bool.isRequired,
};

export default DetailsAnchorMenu;
