import styled from 'styled-components';

export default styled.div`
  position: relative;

  .items {
    display: flex;
    margin-top: 45px;
    margin-right: -115px;
    overflow: hidden;

    & > button {
      padding: 15px;
      border-radius: 4px;
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 125px;
      margin-right: 15px;
      transition: ${({ theme: { transition } }) => transition};

      svg {
        pointer-events: none;
      }
    }
  }

  .chevron-button {
    opacity: 0.7;

    &:disabled {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }

    &:hover {
      opacity: 1;
    }

    svg {
      pointer-events: none;
    }
  }

  .toggle-box {
    position: absolute;
    top: -30px;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 5px;
      padding: 0;

      svg {
        fill: ${({ theme: { colors } }) => colors.blue};
        pointer-events: none;
      }
    }
  }

  .lightbox {
    position: fixed;
    z-index: 9009;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(1, 7, 9, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: ${({ theme: { transition } }) => transition};

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    &-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
      text-align: center;
      height: 100%;

      .close-lightbox {
        position: absolute;
        top: 15px;
        right: 35px;

        svg {
          path {
            stroke: #ffffff;
          }
        }
      }

      & > div {
        width: 100%;
      }

      iframe,
      img {
        max-width: 850px;
        height: auto;
      }

      iframe {
        width: 100%;
        height: 80vh;
        border: 0;
        max-height: 500px;
      }
    }
  }
`;
