import styled, { keyframes } from 'styled-components';

const partnerImages = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Hero = styled.section`
  position: relative;
  padding: 75px 0;
  z-index: 2;

  h1,
  p {
    color: #ffffff;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  p {
    opacity: 0.7;
    margin: 0 auto;
    max-width: 500px;
    margin-bottom: 63px;
  }

  @media (max-width: 767px) {
    h1,
    p {
      display: block;
      width: 92vw;
      max-width: 92vw;
      margin-left: auto;
      margin-right: auto;
    }

    .select-cities,
    .select-search {
      display: block;
      width: 92vw;
      max-width: 92vw;
      margin: auto;

      & > div {
        border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
        border-radius: 4px;
        margin-left: 1px;
      }
    }
  }
`;

export const HeroBackground = styled.div`
  height: 470px;
  background-image: url('/assets/img/coprocur-home-banner.jpg');
  background-size: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-left: -600px;
  margin-right: -600px;
  margin-top: -345px;

  @media (max-width: 1200px) {
    background-size: contain;
  }

  @media (max-width: 767px) {
    background-position: right;
    background-size: auto;
  }
`;
const keyframe = keyframes`
  from {
    transform: translateX(0);
  }
  to{
    transform: translateX(-180vw);
  }
  `;
export const Services = styled.section`
  position: relative;
  z-index: 1;

  .content {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.1);
    min-height: 380px;
    /* height: 380px; */
    margin: 0;
    overflow: hidden;
    padding: 32px 0;
    margin-top: -120px;
    z-index: 1;
    background: white;

    h2 {
      font-size: 32px;

      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 1.3;
        margin-bottom: 10px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: normal;
      opacity: 0.7;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .partner-images {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 62px;
      margin: 62px 54px 0;

      img {
        /* min-width: calc(100% / 8); */
        width: 110px;
        height: 50px;
        margin-bottom: 15px;
        object-fit: contain;
        animation-name: ${partnerImages};
        animation-duration: 0.2s;
      }

      @media (max-width: 979px) {
        margin-left: 20px;
        margin-right: 20px;
        flex-wrap: wrap;

        img {
          min-width: calc(100% / 4);
        }
      }

      .marquee-div {
        margin: 0px;
        padding: 0px;
        height: 50px;
      }

      @media (max-width: 767px) {
        justify-content: center;

        img {
          min-width: calc(100% / 3);
        }
      }
    }
  }

  .services-images {
    display: flex;
    margin-top: 65px;
    animation-name: serviceImages;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &:hover {
      animation-play-state: paused;
    }

    & > div {
      margin-left: 8px;

      @media (max-width: 979px) {
        display: flex;
        margin-left: 0;
      }
    }

    .ps-tag {
      display: block;
      position: relative;
      min-width: 104px;
      max-width: 104px;
      min-height: 125px;
      max-height: 125px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-blend-mode: overlay;
      margin-right: 20px;
      border-radius: 4px;
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};

      @media (max-width: 979px) {
        min-width: 104px;
        margin-right: 10px;
      }

      span {
        position: absolute;
        left: 7px;
        bottom: 9px;
        width: calc(100% - 14px);
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        pointer-events: none;
      }
    }
  }

  @keyframes serviceImages {
    from {
      transform: translateX(0);
    }

    to {
      transform: ${({ serviceImagesLength }) =>
        `translateX(calc(-${serviceImagesLength * 124}px + 200vh - 128px))`};
    }
  }
  .marquee {
    background-color: black;
    // color: tomato;
    width: 80vw;
    height: 20vh;
    overflow: hidden;
  }

  .marquee-content {
    list-style: none;
    height: 100%;
    display: flex;
    animation: ${keyframe} 45s linear infinite;
  }
  @keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(1800vw);
    }
  }
  .marquee-content li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    flex-shrink: 0;
    font-size: 5rem;
    white-space: nowrap;
  }
`;

export const Updates = styled.section`
  padding-top: 32px;
  padding-bottom: 38px;

  @media (max-width: 979px) {
    padding-bottom: 0;
  }

  .cards {
    @media (max-width: 979px) {
      flex-direction: column;
      margin: 0;
    }

    & > div {
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
      border: solid 1px ${({ theme: { colors } }) => colors.deepLightGrey};
      background-color: #ffffff;
      padding: 22px;
      min-width: calc(33.33% - 30px);
      max-width: calc(33.33% - 30px);

      @media (max-width: 979px) {
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 32px;
      }

      &:first-child {
        position: relative;

        &::before {
          content: '“';
          position: absolute;
          font-size: 130px;
          opacity: 0.1;
          left: 0;
          top: 35px;
        }

        .testimonials {
          display: flex;
          overflow: hidden;

          & > div {
            min-width: 100%;
            max-width: 100%;
            transition: transform 0.35s;

            .user {
              margin-top: 30px;

              img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 12px;
              }
            }
          }
        }

        .testimonial-dots {
          list-style: none;
          padding: 0;
          margin: 0;
          margin-top: 70px;
          text-align: center;

          &--chevron {
            svg {
              transition: fill 0.2s;
            }

            &:disabled {
              svg {
                fill: ${({ theme: { colors } }) => colors.grey} !important;
                opacity: 0.4;
              }
            }

            &:hover {
              svg {
                fill: ${({ theme: { colors } }) => colors.black};
              }
            }
          }

          li {
            display: inline-block;
            margin: 0 10px;
            padding: 0;

            button {
              span,
              svg {
                pointer-events: none;
              }

              svg {
                fill: #80c5ff;
              }

              &.testimonial-dot {
                span {
                  display: inline-block;
                  height: 8px;
                  width: 8px;
                  border: 1px solid #80c5ff;
                  border-radius: 50%;
                  vertical-align: top;
                  margin-top: 3px;
                }

                &.active {
                  span {
                    background-color: #80c5ff;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        img {
          display: block;
          height: 141px;
          width: 100%;
          border-radius: 4px;
          object-fit: cover;
          margin-bottom: 16px;
        }

        svg {
          opacity: 0.35;
        }

        .location {
          margin-top: 22px;

          span {
            vertical-align: middle;
            opacity: 0.6;
          }
        }
      }

      h4 {
        font-size: 19px;
        font-weight: 500;
        color: ${({ theme: { colors } }) => colors.darkBlue};
        margin-top: 0;
        margin-bottom: 30px;
      }

      p {
        font-size: 14px;
      }
    }
  }
`;

export const FounderProfile = styled.section`
  text-align: center;
  maxwidth: 979px;
  margin-left: 50px;
  margin-right: 50px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  vertical-align: middle;
  box-sizing: border-box;

  .card {
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px ${({ theme: { colors } }) => colors.deepLightGrey};
    background-color: #ffffff;
    margin: 20px;
  }

  h4 {
    font-size: 19px;
    font-weight: 500;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    margin-top: 10px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    text-align: justify;
    // width: 80%;
    margin: 20px;
  }

  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: '';
    display: table;
    clear: both;
  }

  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }

  .imgBox img {
    height: 200px;
    width: 200px;
    vertical-align: middle;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #615ba6;
  }

  .imgBox {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: relative;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    overflow: hidden;
  }

  .imgBox::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -100%;
    background-color: rgb(0, 0, 0, 0.7);
    height: 100%;
    width: 0%;
    transform: skewX(-40deg);
    transition: all 0.8s;
  }

  .imgBox:hover::before {
    left: -50%;
    width: 200%;
  }

  .content {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
  }

  .content a {
    text-decoration: none;
  }

  .content img {
    height: 70px;
    width: 70px;
    transform: translateY(250px);
    transition: all 0.8s;
  }

  .imgBox:hover .content img {
    transform: translateY(0px);
  }
  .imgBox:hover .content {
    opacity: 1;
  }
`;
