import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';

import Container from './Carousel.styled';

const Carousel = ({ items, className, style, itemWidth = 230 }) => {
  const [step, setStep] = useState(0);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const containerRef = useRef();

  function stepClickHandler(e) {
    const { type } = e.target.dataset;

    if (type === 'prev' && step > 0) setStep(step - 1);
    if (type === 'next' && step < items.length - 1) setStep(step + 1);
  }

  useEffect(() => {
    if (containerRef.current && items.length > 0 && (step === 0 || step)) {
      setTimeout(() => {
        if (containerRef.current) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const chilren = containerRef.current.querySelectorAll('.items > div');
          const lastButtonRect = chilren[
            chilren.length - 1
          ].getBoundingClientRect();

          setIsScrolledToRight(containerRect.right > lastButtonRect.right);
        }
      }, 300);
    }
  }, [items, step]);

  return (
    <Container ref={containerRef} className={className} style={style}>
      <div className="items">
        {items.map((i) => (
          <div
            key={i.key}
            style={{
              minWidth: itemWidth,
              transform: `translateX(-${step * (itemWidth + 15)}px)`,
            }}>
            {i}
          </div>
        ))}
      </div>
      {items.length > 3 && (
        <ul className="toggle-box">
          <li>
            <Button
              type="button"
              data-type="prev"
              className="chevron-button"
              disabled={step === 0}
              onClick={stepClickHandler}>
              <Icon name="chevron" />
            </Button>
          </li>
          <li>
            <Button
              type="button"
              data-type="next"
              className="chevron-button"
              disabled={isScrolledToRight || step === items.length - 1}
              onClick={stepClickHandler}>
              <Icon name="chevron" rotate="180deg" />
            </Button>
          </li>
        </ul>
      )}
    </Container>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  itemWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Carousel;
