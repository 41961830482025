import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 13;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    background-color: #ffffff;
    width: 600px;
    max-width: 100%;
    min-height: 533px;
    margin: 10px;
    padding: 17px 24px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.blue};
    }

    .close-button {
      border-radius: 20px;
      width: 24px;
      height: 24px;
      padding: 2px;
      border: 1px solid;
      opacity: 0.35;
      padding: 1.5px;

      &:hover {
        opacity: 0.75;
      }
    }

    .supplier-row {
      margin: 40px 0 50px;

      & div {
        &:first-child {
          width: 120px;
          height: 76px;
          border-radius: 3px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
          border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
          background-color: #ffffff;
          padding: 9px;
          margin-right: 24px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
        opacity: 0.7;
      }
    }

    form {
      input,
      textarea {
        margin-bottom: 14px;

        &::placeholder {
          opacity: 0.5;
        }

        &.has-error {
          border-color: ${({ theme: { colors } }) => colors.red};
        }
      }

      & > div:not(.right) {
        margin: 0 -7px;

        & > div {
          margin: 0 7px;
        }
      }

      .phone-container {
        position: relative;

        input {
          padding-left: 50px;
        }

        &::before {
          content: '+91';
          position: absolute;
          left: 13px;
          top: 11px;
        }
      }

      .errors {
        list-style: none;
        padding: 0;
        margin: 10px 0;

        @media (max-width: 767px) {
          display: none;
        }

        li {
          padding: 0;
          margin: 0;
          margin-bottom: 10px;
          color: ${({ theme: { colors } }) => colors.red};
        }
      }
    }

    .success-message {
      margin: 110px 0 0;
      flex-wrap: nowrap;

      & > div {
        &:first-child {
          margin-right: 16px;

          svg {
            fill: #13ba58;
          }
        }
      }
    }

    .social-links {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        padding: 0;
        margin: 0 8px;

        a {
          svg {
            fill: ${({ theme: { colors } }) => colors.blue};
          }

          &:hover {
            svg {
              fill: ${({ theme: { colors } }) => colors.blueHover};
            }
          }
        }
      }
    }
  }
`;
