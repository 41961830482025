import actionCreator from 'utils/actionCreator';

export const actions = actionCreator('listing', [
  'SUPPLIERS_SEARCH_REQUEST',
  'SUPPLIERS_SEARCH_SUCCESS',
  'TOGGLE_SEARCH_LOADING',
  'CITIES_REQUEST',
  'CITIES_SUCCESS',
  'TAGS_REQUEST',
  'TAGS_SUCCESS',
  'PS_TAGS_REQUEST',
  'PS_TAGS_SUCCESS',
  'PARTNERS_REQUEST',
  'PARTNERS_SUCCESS',
  'COMPARISION_SUPPLIER_REQUEST',
  'COMPARISION_SUPPLIER_SUCCESS',
  'ADD_CONNECTION',
]);

export function suppliersSearchRequest(payload) {
  return {
    type: actions.SUPPLIERS_SEARCH_REQUEST,
    payload,
  };
}

export function suppliersSearchSuccess(payload) {
  return {
    type: actions.SUPPLIERS_SEARCH_SUCCESS,
    payload,
  };
}

export function toggleSearchLoading(payload) {
  return {
    type: actions.TOGGLE_SEARCH_LOADING,
    payload,
  };
}

export function citiesRequest() {
  return {
    type: actions.CITIES_REQUEST,
  };
}

export function citiesSuccess(payload) {
  return {
    type: actions.CITIES_SUCCESS,
    payload,
  };
}

export function tagsRequest() {
  return {
    type: actions.TAGS_REQUEST,
  };
}

export function tagsSuccess(payload) {
  return {
    type: actions.TAGS_SUCCESS,
    payload,
  };
}

export function psTagsRequest() {
  return {
    type: actions.PS_TAGS_REQUEST,
  };
}

export function psTagsSuccess(payload) {
  return {
    type: actions.PS_TAGS_SUCCESS,
    payload,
  };
}

export function partnersRequest() {
  return {
    type: actions.PARTNERS_REQUEST,
  };
}

export function partnersSuccess(payload) {
  return {
    type: actions.PARTNERS_SUCCESS,
    payload,
  };
}

export function comparisionSupplierRequest(payload) {
  return {
    type: actions.COMPARISION_SUPPLIER_REQUEST,
    payload,
  };
}

export function comparisionSupplierSuccess(payload) {
  return {
    type: actions.COMPARISION_SUPPLIER_SUCCESS,
    payload,
  };
}

export function addConnection(payload, callback) {
  return {
    type: actions.ADD_CONNECTION,
    payload,
    callback,
  };
}
