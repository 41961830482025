import { actions } from './actions';

const INITIAL_STATE = {
  snackbar: {
    showSnackbar: false,
    message: '',
  },
  compareSuppliers: [],
  showContactSupplier: false,
};

export default function appReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case actions.SHOW_SNACKBAR:
      return { ...state, snackbar: { showSnackbar: true, message: payload } };
    case actions.HIDE_SNACKBAR:
      return { ...state, snackbar: { showSnackbar: false, message: '' } };
    case actions.UPDATE_COMPARE_SUPPLIERS:
      return { ...state, compareSuppliers: payload };
    case actions.TOGGLE_CONTACT_SUPPLIER:
      return { ...state, showContactSupplier: payload };
    default:
      return state;
  }
}
