/* eslint-disable camelcase */
import { call, put, takeLatest, select, delay, all } from 'redux-saga/effects';
import axios from 'axios';

import history from 'utils/history';
import api from 'utils/api';

import {
  actions,
  loginSuccess,
  setLoginError,
  getUserDetails,
  signUpUpdate,
  forgotPasswordUpdate,
  profileUpdate,
  changePasswordUpdate,
  supplierCitiesSuccess,
  allCitiesSuccess,
  allClientsSuccess,
  userSupplierSuccess,
  supplierSignUpUser,
} from './actions';
import * as selectors from './selectors';

async function uploadMedia(file, meta = null) {
  const bodyFormData = new FormData();

  bodyFormData.append('file', file);

  const { data } = await axios({
    method: 'POST',
    url: api.getMedia,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return { file: data.file, meta };
}

function* loginRequestSaga({ payload }) {
  yield put(setLoginError(null));

  try {
    const loginResponse = yield call(axios.post, api.auth, {
      user_email: payload.email,
      user_pwd: payload.password,
    });

    if (loginResponse.data.x_auth_token) {
      yield put(getUserDetails(loginResponse.data.x_auth_token));
      window.localStorage.setItem(
        'user_auth_token',
        loginResponse.data.x_auth_token,
      );
      window.localStorage.setItem('user_auth_time', new Date().getTime());
      if (loginResponse.data.is_supplier) {
        const { data } = yield call(
          axios.get,
          `${api.getSupplier}/user_id/${loginResponse.data._id}`,
        );
        yield put(userSupplierSuccess(data));
      }
      history.push('/');
    } else yield put(setLoginError("There's some issue logging you in."));
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error_msg
    )
      yield put(setLoginError(error.response.data.error_msg));
  }
}

export function* watchLoginReguest() {
  yield takeLatest(actions.LOGIN_REQUEST, loginRequestSaga);
}

function* getUserDetailsSaga({ payload }) {
  try {
    const { data } = yield call(axios, {
      method: 'get',
      url: api.getUserByToken,
      headers: { 'x-auth-token': payload },
    });

    yield put(
      loginSuccess({
        ...data,
        x_auth_token: payload,
      }),
    );

    if (data.is_supplier) {
      const { data: supplier } = yield call(
        axios.get,
        `${api.getSupplier}/user_id/${data._id}`,
      );
      yield put(userSupplierSuccess(supplier));
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error_msg &&
      error.response.data.error_msg === 'User not found'
    ) {
      window.localStorage.removeItem('user_auth_token');
      window.localStorage.removeItem('user_auth_time');
    }
  }
}

export function* watchGetUserDetails() {
  yield takeLatest(actions.GET_USER_DETAILS, getUserDetailsSaga);
}

function* generateOTPSaga({ payload }) {
  const signUp = yield select(selectors.signUp);
  const forgotPassword = yield select(selectors.forgotPassword);

  try {
    yield call(axios, {
      method: 'get',
      url: `${api.generateOTP}?email=${payload.email}&first_name=${payload.firstName}&last_name=${payload.lastName}`,
    });
    yield put(signUpUpdate({ ...signUp, otpSent: true }));
    yield put(forgotPasswordUpdate({ ...forgotPassword, otpSent: true }));
  } catch (error) {
    yield put(signUpUpdate({ ...signUp, otpError: error.response.data }));
    yield put(
      forgotPasswordUpdate({
        ...forgotPassword,
        otpError: error.response.data,
      }),
    );
  }
}

export function* watchGenerateOTP() {
  yield takeLatest(actions.GENERATE_OTP, generateOTPSaga);
}

function* checkOTPSaga({ payload }) {
  const signUp = yield select(selectors.signUp);
  const forgotPassword = yield select(selectors.forgotPassword);

  yield put(signUpUpdate({ ...signUp, otpError: null }));

  try {
    yield call(axios, {
      method: 'post',
      url: api.generateOTP,
      data: payload,
    });
    yield put(signUpUpdate({ ...signUp, otpConfirmed: true, otpError: '' }));
    yield put(
      forgotPasswordUpdate({
        ...forgotPassword,
        otpConfirmed: true,
        otpError: '',
      }),
    );
  } catch (error) {
    yield put(
      signUpUpdate({ ...signUp, otpError: error.response.data.error_msg }),
    );
    yield put(
      forgotPasswordUpdate({
        ...forgotPassword,
        otpError: error.response.data.error_msg,
      }),
    );
  }
}

export function* watchCheckOTP() {
  yield takeLatest(actions.CHECK_OTP, checkOTPSaga);
}

function* signUpUserSaga({ payload }) {
  const signUp = yield select(selectors.signUp);
  yield put(signUpUpdate({ ...signUp, otpError: null, signUpError: null }));

  try {
    let userRes = yield call(axios, {
      method: 'post',
      url: api.users,
      data: payload,
    });

    userRes = { ...userRes, x_auth_token: userRes.x_auth_token };
    delete userRes.x_auth_token;

    window.localStorage.setItem('user_auth_token', userRes.data.x_auth_token);
    window.localStorage.setItem('user_auth_time', new Date().getTime());
    yield put(loginSuccess(userRes.data));
    yield put(
      signUpUpdate({
        otpSent: false,
        otpConfirmed: false,
        otpError: null,
        signUpError: null,
      }),
    );
    history.push('/');
    console.log("Reloaded from Browser History");
  } catch (error) {
    console.log("Dipesh - Error here", error);
    yield put(
      signUpUpdate({ ...signUp, signUpError: error.response.data.error_msg }),
    );
  }
}

export function* watchSignUpUser() {
  yield takeLatest(actions.SIGN_UP_USER, signUpUserSaga);
}

function* forgotPasswordSaga({ payload }) {
  const forgotPassword = yield select(selectors.forgotPassword);

  try {
    yield call(axios, {
      method: 'get',
      url: `${api.forgotPassword}/${payload}`,
    });
    yield put(forgotPasswordUpdate({ ...forgotPassword, emailSent: true }));
  } catch (error) {
    yield put(
      forgotPasswordUpdate({
        ...forgotPassword,
        forgotPasswordError: error.response.data.error_msg,
      }),
    );
  }
}

export function* watchForgotPassword() {
  yield takeLatest(actions.FORGOT_PASSWORD, forgotPasswordSaga);
}

function* updateUserSaga({ payload }) {
  const putData = {
    user_first_name: payload.user_first_name,
    user_last_name: payload.user_last_name,
    user_email: payload.user_email,
    user_phone: payload.user_phone,
    is_supplier: payload.is_supplier,
    is_admin: payload.is_admin,
    is_notify: payload.is_notify,
  };

  if (payload.user_pwd) putData.user_pwd = payload.user_pwd;

  try {
    yield call(axios, {
      method: 'put',
      url: `${api.users}/${payload._id}`,
      headers: { 'x-auth-token': payload.x_auth_token },
      data: putData,
    });

    yield put(loginSuccess(payload));
    yield put(
      profileUpdate({
        profileUpdated: true,
        profileError: null,
      }),
    );
    yield delay(7000);
    yield put(
      profileUpdate({
        profileUpdated: false,
        profileError: null,
      }),
    );
  } catch (error) {
    yield put(
      profileUpdate({
        profileUpdated: false,
        profileError: error.response.data.error_msg,
      }),
    );
  }
}

export function* watchUpdateUser() {
  yield takeLatest(actions.UPDATE_USER, updateUserSaga);
}

function* changePasswordSaga({ payload }) {
  try {
    yield call(axios, {
      method: 'put',
      url: `${api.changePassword}/${payload._id}`,
      headers: { 'x-auth-token': payload.x_auth_token },
      data: { old_pwd: payload.old_pwd, new_pwd: payload.new_pwd },
    });
    yield put(
      changePasswordUpdate({
        passwordChanged: true,
        changePasswordError: null,
      }),
    );
    yield delay(7000);
    yield put(
      changePasswordUpdate({
        passwordChanged: false,
        changePasswordError: null,
      }),
    );
  } catch (error) {
    yield put(
      changePasswordUpdate({
        passwordChanged: false,
        changePasswordError: error.response.data.error_msg,
      }),
    );
  }
}

export function* watchChangePassword() {
  yield takeLatest(actions.CHANGE_PASSWORD, changePasswordSaga);
}

function* supplierCitiesRequestSaga() {
  try {
    const { data } = yield call(axios.get, api.getSupplierCities);

    yield put(supplierCitiesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* watchSupplierCitiesRequest() {
  yield takeLatest(actions.SUPPLIER_CITIES_REQUEST, supplierCitiesRequestSaga);
}

function* allCitiesRequestSaga() {
  try {
    const { data } = yield call(axios.get, api.getAllCities);

    yield put(allCitiesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* watchAllCitiesRequest() {
  yield takeLatest(actions.ALL_CITIES_REQUEST, allCitiesRequestSaga);
}

function* allClientsRequestSaga() {
  try {
    const { data } = yield call(axios.get, api.getAllClients);

    yield put(allClientsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* watchAllClientsRequest() {
  yield takeLatest(actions.ALL_CLIENTS_REQUEST, allClientsRequestSaga);
}

function* addClientDetailSaga({ payload, callback }) {
  try {
    const { image, name } = payload;
    const uploadedImage = yield call(uploadMedia, image);
    const { data: newClient } = yield call(axios.post, api.getAllClients, {
      client_name: name,
      client_logo: uploadedImage.file._id,
    });

    callback({ status: 'success', data: newClient });
  } catch (error) {
    callback({ status: 'error', data: error.response.data });
  }
}

export function* watchAddClientDetail() {
  yield takeLatest(actions.ADD_CLIENT_DETAIL, addClientDetailSaga);
}

function* createSupplierSaga({ payload, callback }) {
  const { user } = yield select(selectors.supplierSignUp);

  try {
    const { data: newUser } = yield call(axios.post, api.users, {
      user_email: user.email,
      user_first_name: user.firstName,
      user_last_name: user.lastName,
      user_pwd: payload.user.passwordOne,
      // otp: user.otp,
      otp: '99999',
      is_supplier: true,
      is_notify: payload.user.wantNotifications,
      user_phone: payload.user.mobileNumber,
    });
    const { data } = yield call(
      axios.post,
      api.getSupplier2,
      {
        // ...payload.supplier,
        // contact: [{ ...payload.supplier.contact[0], email: user.email }],
        user_id: newUser._id,
        supplier_name: payload.supplier.supplier_name,
      },
      { headers: { 'x-auth-token': newUser.x_auth_token } },
    );

    if (newUser.x_auth_token) {
      yield put(getUserDetails(newUser.x_auth_token));
      window.localStorage.setItem('user_auth_token', newUser.x_auth_token);
      window.localStorage.setItem('user_auth_time', new Date().getTime());
    }

    yield put(userSupplierSuccess(data));
    yield put(supplierSignUpUser({}));
    callback({ status: 'success', data });
    history.push('/sign-up/supplier/success');
  } catch (error) {
    callback({ status: 'error', data: error.response.data });
  }
}

export function* watchCreateSupplier() {
  yield takeLatest(actions.CREATE_SUPPLIER, createSupplierSaga);
}

function* updateSupplierSaga({ payload, callback }) {
  if (Object.keys(payload.newChanges).length === 0) return;

  let uploadSupplier = { ...payload.newChanges };
  const user = yield select(selectors.currentUser);
  let files = [];

  Object.keys(uploadSupplier).forEach((key) => {
    if (uploadSupplier[key] instanceof File)
      files.push(uploadMedia(uploadSupplier[key], key));
    if (
      Array.isArray(uploadSupplier[key]) &&
      uploadSupplier[key].some((i) => i instanceof File)
    )
      files = [
        ...files,
        ...uploadSupplier[key]
          .filter((i) => i instanceof File)
          .map((i) => uploadMedia(i, key)),
      ];
  });

  if (files.length > 0) {
    const uploadedFiles = yield all(files);

    uploadedFiles.forEach((i) => {
      if (Array.isArray(uploadSupplier[i.meta]))
        uploadSupplier[i.meta] = [
          ...uploadSupplier[i.meta].filter((f) => typeof f === 'string'),
          i.file._id,
        ];
      else uploadSupplier[i.meta] = i.file._id;
    });
  }

  uploadSupplier = { ...payload.userSupplier, ...uploadSupplier };
  const {
    _id,
    __v,
    is_searchable,
    object_creation_timestamp,
    object_update_timestamp,
    unapproved_supplier_id,
    ...uploadSupplierSend
  } = uploadSupplier;

  try {
    if (payload.userSupplier && payload.userSupplier._id) {
      const { data } = yield call(
        axios.put,
        `${api.getSupplier}/${payload.userSupplier._id}`,
        uploadSupplierSend,
        { headers: { 'x-auth-token': user.x_auth_token } },
      );
      yield put(userSupplierSuccess(data));
    } else
      yield call(
        axios.post,
        api.getSupplier,
        {
          ...uploadSupplierSend,
          user_id: user._id,
        },
        { headers: { 'x-auth-token': user.x_auth_token } },
      );

    callback({ type: 'success' });
  } catch (error) {
    callback({ type: 'error', data: error.response.data });
  }
}

export function* watchUpdateSupplier() {
  yield takeLatest(actions.UPDATE_SUPPLIER, updateSupplierSaga);
}
