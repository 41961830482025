import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import theme from 'styles/theme';
import Form from './SearchForm.styled';

import SelectContainer from './SelectContainer';

const LoadingIndicator = () => <></>;

const SearchForm = ({ cities, tags, searchHandler, isHome, defaults }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [city, setCity] = useState([]);
  const [search, setSearchKeyword] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  const commonStyles = {
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      textAlign: 'left',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'transparent',
    }),
  };

  function searchInputChangeHandler(input) {
    setMenuOpen(input && input.length > 0);
  }

  function searchFocusHandler() {
    if (search?.value.length > 0) setMenuOpen(true);
  }

  function cityChange(val) {
    setIsValidated(false);
    if (val === null) val = [];
    setCity(val);
    if (search && val && val.length > 0)
      searchHandler({
        search_text: search.value,
        cities: val.map((i) => i.value),
      });
  }

  function searchChangeHandler(e) {
    setIsValidated(false);
    if (city && city.length > 0 && e?.value)
      searchHandler({ search_text: e.value, cities: city.map((i) => i.value) });
    else {
      setSearchKeyword(e);
      setIsValidated(true);
    }
  }

  function searchClickHandler() {
    setIsValidated(true);
  }

  useEffect(() => {
    if (defaults && defaults.searchKeyword && tags && tags.length > 0)
      setSearchKeyword(tags.find((i) => i.value === defaults.searchKeyword));
  }, [defaults, tags]);

  useEffect(() => {
    if (defaults && defaults.searchCities && cities && cities.length > 0)
      setCity(
        cities.filter((i) =>
          defaults.searchCities.some(
            (j) => i.value.toLowerCase() === j.toLowerCase(),
          ),
        ),
      );
  }, [defaults, cities]);

  return (
    <Form
      isHome={isHome}
      className={isHome ? 'center' : ''}
      style={
        isHome ? { filter: 'drop-shadow(0 1px 4px rgba(0, 0, 0, 0.06))' } : {}
      }>
      <Select
        options={cities}
        onChange={cityChange}
        value={city}
        className="select-cities"
        classNamePrefix="select-cities"
        placeholder="Select city"
        isMulti
        isClearable={false}
        isLoading={cities.length === 0}
        components={{ LoadingIndicator }}
        styles={{
          ...commonStyles,
          placeholder: (provided) => ({
            ...provided,
            whiteSpace: 'nowrap',
            fontSize: isHome ? 19 : 14,
          }),
          control: (provided, state) => ({
            ...provided,
            maxWidth: 320,
            borderRadius: '4px 0 0 4px',
            boxShadow: 'unset',
            zIndex: state.isFocused ? '1' : '0',
            '@media (max-width: 767px)': {
              maxWidth: '100%',
              marginBottom: 7,
            },
            /* eslint-disable indent */
            ...(isValidated
              ? {
                  borderColor:
                    city.length === 0
                      ? theme.colors.red
                      : theme.colors.deepLightGrey,
                  zIndex: city.length === 0 ? 1 : 0,
                }
              : {}),
            /* eslint-enable indent */
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: isHome ? 11 : '2px 8px',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            fontSize: isHome ? 19 : 14,
            padding: '0px 3px',
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            display: city.length === 1 ? 'none' : 'block',
            ':hover': {
              backgroundColor: theme.colors.lightGrey,
              color: theme.colors.red,
            },
            '> svg': {
              verticalAlign: 'middle',
            },
          }),
        }}
      />
      <Creatable
        options={[...tags, { value: 'searchall', label: 'Search all' }]}
        value={search}
        onInputChange={searchInputChangeHandler}
        onChange={searchChangeHandler}
        onFocus={searchFocusHandler}
        className="select-search"
        classNamePrefix="select-search"
        placeholder="Search here"
        isSearchable
        isClearable
        formatCreateLabel={(inputValue) => `Search "${inputValue}"`}
        menuIsOpen={isMenuOpen}
        components={{ SelectContainer }}
        isHome={isHome}
        searchClickHandler={searchClickHandler}
        styles={{
          ...commonStyles,
          placeholder: (provided) => ({
            ...provided,
            fontSize: isHome ? 19 : 14,
          }),
          control: (provided) => ({
            ...provided,
            borderRadius: '0 4px 4px 0',
            marginLeft: '-1px',
            boxShadow: 'unset',
            /* eslint-disable indent */
            ...(isValidated
              ? {
                  borderColor: !search
                    ? theme.colors.red
                    : theme.colors.deepLightGrey,
                }
              : {}),
            /* eslint-enable indent */
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: isHome ? '11px 15px' : '2px 8px',
          }),
        }}
      />
    </Form>
  );
};

SearchForm.propTypes = {
  cities: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  searchHandler: PropTypes.func.isRequired,
  isHome: PropTypes.bool,
  defaults: PropTypes.object,
};

export default SearchForm;
