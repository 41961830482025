import styled from 'styled-components';

const Container = styled.div`
  max-width: ${({ width }) => width || '100vw'};
  margin: 0 auto;
  width: 100%;
  padding: 0 64px;

  @media (max-width: 1200px) {
    max-width: 94% !important;
    padding: 0;
  }
`;

export default Container;
