import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';

const SupplierProfile = lazy(() =>
  import('modules/Users/container/SupplierProfile'),
);

const SupplierProfilePage = () => (
  <DashboardLayout hideFooter>
    <Helmet title="Profile | EasyProcur" />
    <Suspense fallback={<Loading />}>
      <SupplierProfile />
    </Suspense>
  </DashboardLayout>
);

export default SupplierProfilePage;
