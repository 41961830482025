import React, {useEffect} from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import history from 'utils/history';

import ListingPage from './listing';
import LoginPage from './login';
import SignUpPage from './signUp';
import ForgotPasswordForm from './forgotPassword';
import DashboardPage from './dashboard';
import ProfilePage from './profile';
import ComparePage from './compare';
import ContactUsPage from './contact';
import HomePage from './home';
import PrivacyPolicyPage from './privacyPolicy';
import AboutPage from './about';
import SupplierPage from './supplier';
import TermsPage from './terms';
import SignUpSupplierSuccessPage from './signUpSupplierSuccess';
import ErrorPage from './404';
import SupplierProfile from './supplierProfile';

import FreshChat from 'react-freshchat';
const preChatTemplate = {
  //Form header color and Submit button color.
  mainbgColor: '#0aa4db',
  //Form Header Text and Submit button text color.
  maintxColor: '#fff',
  //Chat Form Title
  heading: 'GadgetGod',
  //Chat form Welcome Message
  textBanner: 'We can\'t wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.',
  //Submit Button Label.
  SubmitLabel: 'Start Chat',
  //Fields List - Maximum is 5
  //All the values are mandatory and the script will not work if not available.
  fields : {
    field1 : {
      //Type can be either text or title
      type: "title",
      //Label for Field Title, can be in any language
      label: "Title",
      //Field ID for Title
      fieldId: "title",
      //Required "yes" or "no"
      required: "yes",
      //Error text to be displayed
      error: "Please Enter a valid Title"
    },
    field2 : {
      //Type for Name - Do not Change
      type: "name",
      //Label for Field Name, can be in any language
      label: "Name",
      //Default - Field ID for Name - Do Not Change
      fieldId: "name",
      //Required "yes" or "no"
      required: "yes",
      //Error text to be displayed
      error: "Please Enter a valid name"
    },
    field3 : {
      //Type for Email - Do Not Change
      type: "email",
      //Label for Field Email, can be in any language
      label: "Email",
      //Default - Field ID for Email - Do Not Change
      fieldId: "email",
      //Required "yes" or "no"
      required: "yes",
      //Error text to be displayed
      error: "Please Enter a valid Email"
    },
    field4 : {
      //Type for Phone - Do Not Change
      type: "phone",
      //Label for Field Phone, can be in any language
      label: "Phone",
      //Default - Field ID for Phone - Do Not Change
      fieldId: "phone",
      //Required "yes" or "no"
      required: "yes",
      //Error text to be displayed
      error: "Please Enter a valid Phone Number"
    },
    field5 : {
      //Type for Dropdown
      type: "dropdown",
      //Label for Field Dropdown, can be in any language
      label: "Plan",
      //Field ID for Plan Dropdown
      fieldId: "plan",
      //Required "yes" or "no"
      required: "yes",
      //Error text to be displayed
      error: "Please select an option",
      //Options for the Dropdown field
      options: ['Sprout','Blossom','Garden','Estate','Forest']
    }
  }
};
const Pages = () => {

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://wchat.freshchat.com/js/widget.js";
  //   script.async = true;
  //   script.src = "//in.fw-cdn.com/30413213/232011.js";
  //   script.chat = true;
  //   document.body.appendChild(script);
  // });

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = "https://wchat.freshchat.com/js/widget.js";
    // script.src = "https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js";
    script.async = true;
    script.src = "//in.fw-cdn.com/30413213/232011.js";
    script.chat = true;
    document.body.appendChild(script);
  });

  return (
  <Router history={history}>
    {/* <FreshChat
        token="996450b4-d798-4a4c-a55a-4be6cf5c9d33"
        email= 'Dev@easyprocur.com'
        first_name= 'Dev'
        onInit={widget => {
          widget.on("widget:closed", function() {
            document.getElementById('fc_frame').style.display = 'block';
          })
        }}
      /> */}
      {/* <FreshChat
        token="fa5ad5f8-83be-4401-addd-f75b8cd84e53"
        email= 'Dev@easyprocur.com'
        host="https://wchat.freshchat.com"
        config= {{cssNames: {
          //The below element is mandatory. Please add any custom class or leave the default.
          widget: 'custom_fc_frame',
          //The below element is mandatory. Please add any custom class or leave the default.
          expanded: 'custom_fc_expanded'
        }}}
        first_name= 'Dev'
        onInit={widget => {
          console.log(widget);
          console.log(window.fcPreChatform.fcWidgetInit);
          window.fcPreChatform.fcWidgetInit(preChatTemplate);
          // widget.on("widget:closed", function() {
          //   document.getElementById('fc_frame').style.display = 'block';
          // })
        }}
      /> */}
    <Switch>
      <Route exact path="/sign-up" component={SignUpPage} />
      <Route exact path="/sign-up/supplier" component={SignUpPage} />
      <Route
        exact
        path="/sign-up/supplier/success"
        component={SignUpSupplierSuccessPage}
      />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/forgot-password" component={ForgotPasswordForm} />
      <Route
        exact
        path="/dashboard"
        component={
          window.localStorage.getItem('user_auth_token')
            ? DashboardPage
            : ErrorPage
        }
      />
      <Route
        exact
        path="/profile"
        component={
          window.localStorage.getItem('user_auth_token')
            ? ProfilePage
            : ErrorPage
        }
      />
      <Route
        exact
        path="/profile/supplier"
        component={
          window.localStorage.getItem('user_auth_token')
            ? SupplierProfile
            : ErrorPage
        }
      />
      <Route
        path="/compare/:supplier1/:supplier2/:supplier3"
        component={ComparePage}
      />
      <Route path="/compare/:supplier1/:supplier2" component={ComparePage} />
      <Route exact path="/contact-us" component={ContactUsPage} />
      <Route exact path="/search" component={ListingPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/terms" component={TermsPage} />
      <Route exact path="/about-us" component={AboutPage} />
      <Route exact path="/s/:supplierID" component={SupplierPage} />
      <Route exact path="/" component={HomePage} />
      <Route path="*" component={ErrorPage} />
    </Switch>
  </Router>
)};

export default Pages;
