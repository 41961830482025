import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Carousel from 'components/Carousel';

import { DirectorsSection } from './SupplierDetails.styled';

const Directors = ({ supplier }) =>
  supplier.director && supplier.director.length > 0 ? (
    <DirectorsSection>
      <h4>
        <span>Directors</span>
      </h4>
      <Carousel
        itemWidth={150}
        items={supplier.director.map((i) => (
          <div key={i._id} className="directors-carousel-item">
            <h3>{i.name}</h3>
            <p className="designation">{i.designation}</p>
            <p className="date">
              {`Since ${dayjs(i.appointment_date).format('MMM M, YYYY')}`}
            </p>
          </div>
        ))}
      />
    </DirectorsSection>
  ) : (
    <></>
  );

Directors.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default Directors;
