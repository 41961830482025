import React from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/Carousel';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';

import { ContactSection } from './SupplierDetails.styled';

const Contact = ({ supplier, contactHandler }) =>
  supplier.contact && supplier.contact.length > 0 ? (
    <ContactSection id="contact">
      <h4>
        <span>Contact</span>
      </h4>
      <Carousel
        items={supplier.contact.map((i) => (
          <div key={i._id} className="carousel-item">
            {i.address && (
              <Row align="flex-start">
                <Column>
                  <Icon name="location" />
                </Column>
                <Column>{i.address}</Column>
              </Row>
            )}
            {i.city && (
              <Row align="flex-start">
                <Column>
                  <Icon name="location" />
                </Column>
                <Column>{i.city}</Column>
              </Row>
            )}
            {i.pin && (
              <Row align="flex-start">
                <Column>
                  <Icon name="location" />
                </Column>
                <Column>{i.pin}</Column>
              </Row>
            )}
            {i.phone &&(
              <Row align="flex-start">
              <Column>
                <Icon name="call" />
              </Column>
              <Column>{i.phone}</Column>
            </Row>
            )}
            
            {i.address && (
              <Row align="flex-start">
                <Column>
                  <Icon name="directions" />
                </Column>
                <Column>
                  <a
                    href={`http://maps.google.com/?q=${i.address}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Get Directions
                  </a>
                </Column>
              </Row>
            )}
            {i.email && (
              <Row align="flex-start" wrap="nowrap" className="blue-row">
                <Column>
                  <Icon name="email" />
                </Column>
                <Column>
                  <Button
                    type="button"
                    onClick={contactHandler}
                    className="left"
                    style={{ lineHeight: 'inherit', wordBreak: 'break-all' }}>
                    {i.email}
                  </Button>
                </Column>
              </Row>
            )}
          </div>
        ))}
      />
    </ContactSection>
  ) : (
    <></>
  );

Contact.propTypes = {
  supplier: PropTypes.object.isRequired,
  contactHandler: PropTypes.func.isRequired,
};

export default Contact;
