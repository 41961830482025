import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
  padding-top: 45px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 364px;
    width: 100%;
    background-color: ${({ theme: { colors } }) => colors.blue};
    opacity: 0.1;
  }

  .content {
    position: relative;
    z-index: 1;
  }

  h1 {
    padding-top: 145px;
    padding-bottom: 55px;
    font-size: 28px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    opacity: 0.7;
  }

  .form-container {
    width: 450px;
    padding: 40px 25px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px ${({ theme: { colors } }) => colors.deepLightGrey};
    background-color: #ffffff;

    form {
      padding-top: 32px;

      input {
        margin-bottom: 15px;

        &:disabled {
          background-color: #ffffff;
        }
      }

      button {
        margin-top: 35px;
      }
    }
  }
`;
