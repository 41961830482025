import styled from 'styled-components';

export const DetailsContainer = styled.div`
  position: relative;
  z-index: 1;
  padding: 25px 80px 40px;

  @media (max-width: 1200px) {
    padding: 25px 30px 40px;
  }

  @media (max-width: 767px) {
    padding: ${({ isSupplierPage }) =>
    /* eslint-disable */
      isSupplierPage ? '25px 0 40px' : '25px 30px 40px'};
    /* eslint-enable */
  }

  .anchor-placeholder {
    height: 75px;

    @media (max-width: 767px) {
      height: 55px;
    }
  }

  h4 {
    position: relative;
    opacity: 0.7;
    margin-top: 35px;
    margin-bottom: 15px;

    span {
      position: relative;
      z-index: 1;
      background-color: #ffffff;

      @media (max-width: 767px) {
        background-color: ${({ theme: { colors } }) => colors.lightGrey};
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      background-color: ${({ theme: { colors } }) => colors.deepLightGrey};
    }
  }
`;

export const AnchorMenu = styled.div`
  position: fixed;
  height: 75px;
  width: ${({ isDashboard }) => (isDashboard ? '690px' : 'calc(100% - 695px)')};
  z-index: 2;
  transition: transform 0.2s;

  ${({ isSupplierPage }) => isSupplierPage && `width: calc(100% - 308px);`}

  ${({ isListingPage }) => isListingPage && `transform: translateY(-6px);right: 9vw;`}

  @media (max-width: 1200px) {
    width: ${({ isDashboard }) => (isDashboard ? '690px' : 'calc(70% - 60px)')};
  }

  .anchor-dropdown,
  .clear-button {
    display: none;
  }

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    top: ${({ isSupplierPage }) => (isSupplierPage ? '52px' : '0')};
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    height: 48px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

    ul {
      display: none;
    }

    .anchor-dropdown,
    .clear-button {
      display: block;
    }

    .anchor-dropdown {
      position: relative;

      select {
        appearance: none;
        border-radius: 16px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
        border: solid 1px ${({ theme: { colors } }) => colors.deepLightGrey};
        background-color: #ffffff;
        padding: 6px 25px 6px 16px;
      }

      svg {
        position: absolute;
        top: 9px;
        right: 13px;
      }
    }

    .clear-button {
      border-radius: 50%;
      border: 1px solid #eb8d89;
      padding: 1px;

      svg path {
        stroke: #eb8d89;
      }
    }
  }

  ul {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    margin: 0;
    padding: 0 10px;
    max-width: 100%;
    white-space: nowrap;
    border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
    border-radius: 50px;
    background-color: #ffffff;

    li {
      display: inline-block;
      padding: 0 10px;
      margin: 0;

      a {
        display: inline-block;
        padding: 5px 0 4px;
        letter-spacing: 0.5px;
        color: ${({ theme: { colors } }) => colors.grey};

        &.active {
          color: ${({ theme: { colors } }) => colors.blue};
          border-bottom: 2px solid ${({ theme: { colors } }) => colors.blue};
        }
      }
    }
  }
`;

export const BriefSection = styled.div`
  h3 {
    @media (max-width: 1200px) {
      font-size: 20px;
    }
  }

  & > div {
    &:first-child {
      @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;
      }

      & > div {
        &:first-child {
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        &:nth-child(2) {
          border-right: 1px solid
            ${({ theme: { colors } }) => colors.deepLightGrey};

          @media (max-width: 979px) {
            border-right: 0;
          }

          p {
            font-size: 14px;
            margin-bottom: 28px;
            opacity: 0.7;
          }

          a {
            color: ${({ theme: { colors } }) => colors.black};
            font-size: 14px;

            &:hover,
            &:focus {
              color: ${({ theme: { colors } }) => colors.blue};
            }
          }
        }

        &:last-child {
          @media (max-width: 979px) {
            min-width: calc(100% - 30px);
            display: flex;
            justify-content: flex-start;
            border-top: 1.5px solid
              ${({ theme: { colors } }) => colors.deepLightGrey};
            border-bottom: 1.5px solid
              ${({ theme: { colors } }) => colors.deepLightGrey};
            margin-top: 24px;
            max-width: calc(100vw - 60px);
            overflow-x: auto;
          }

          & > div {
            margin: 16px 0;
            min-width: 120px;

            a,
            button {
              font-weight: 500;
              font-size: 14px;
              color: ${({ theme: { colors } }) => colors.blue};

              svg {
                fill: ${({ theme: { colors } }) => colors.blue};
                margin-right: 12px;
              }

              &:hover,
              &:focus {
                color: ${({ theme: { colors } }) => colors.blueHover};

                svg {
                  fill: ${({ theme: { colors } }) => colors.blueHover};
                }
              }
            }
          }
        }
      }

      .company-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        height: 120px;
        width: 120px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
        border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
        background-color: #ffffff;
        border-radius: 3px;
      }
    }

    &.numbers {
      margin-top: 42px;
      font-size: 14px;

      @media (max-width: 767px) {
        text-align: center;
        margin-bottom: 30px;
      }

      & > span {
        &:nth-child(odd) {
          opacity: 0.35;
          margin-right: 12px;
        }

        &:nth-child(even) {
          opacity: 0.7;
          margin-right: 50px;
        }
      }
    }

    &.clients {
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
      background-color: #ffffff;
      padding: 15px 0;
      margin: 5px 0;
      white-space: nowrap;
      overflow: hidden;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      img {
        max-height: 50px;
        margin: 0 20px;
        opacity: 1;
      }
    }
  }

  .share-menu {
    position: absolute;
    top: 35px;
    background-color: #ffffff;
    list-style: none;
    padding: 3px 0;
    margin: 0;
    opacity: 0;
    min-width: 130px;
    transform: scale(0.95);
    transform-origin: left top;
    pointer-events: none;
    border-radius: 4px;
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0 0 1px #d9dadb);
    z-index: -1;
    transition: all 0.2s;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 5px 4px;
      border-color: transparent transparent #ffffff transparent;
    }

    &.is-active {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
      z-index: 7;
    }

    li {
      margin: 0;
      padding: 3px 10px 3px 12px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .close-share-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 6;
    cursor: default;
  }
`;

export const AboutSection = styled.div`
  p {
    font-size: 14px;
    line-height: 1.57;
    opacity: 0.7;
  }

  .about-carousel-item {
    font-size: 12px;

    h3 {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 7px;
    }
  }
`;

export const ContactSection = styled.div`
  .carousel-item {
    font-size: 12px;
    opacity: 0.7;

    & > div {
      margin-bottom: 10px;

      & > div {
        &:first-child {
          max-width: 16px;
          margin-right: 0;

          svg {
            width: 16px;
            opacity: 0.35;
          }
        }
      }

      a {
        color: initial;
      }

      &.blue-row {
        svg {
          fill: ${({ theme: { colors } }) => colors.blue};
          opacity: 1 !important;
        }

        button {
          color: ${({ theme: { colors } }) => colors.blue};
          opacity: 1 !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export const ServicesSection = styled.div`
  .offerings {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;

    & > div {
      position: relative;
      width: 104px;
      height: 125px;
      border-radius: 4px;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 17%,
        rgba(0, 0, 0, 0) 38%,
        rgba(0, 0, 0, 0.1) 49%,
        rgba(0, 0, 0, 0.65) 70%,
        rgba(0, 0, 0, 0.85) 88%,
        rgba(0, 0, 0, 0.9)
      );
      background-blend-mode: overlay;
      margin: 8px;
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};

      span {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.58;
        color: #ffffff;
        text-transform: capitalize;
      }
    }
  }
`;

export const TestimonialSection = styled.div`
  .testimonial-carousel-item {
    position: relative;
    height: 100%;

    &::before {
      content: '“';
      font-size: 140px;
      opacity: 0.1;
      position: absolute;
      top: -18px;
      left: -13px;
      line-height: 1;
    }

    p {
      font-size: 14px;

      &:first-child {
        font-style: italic;
        padding-bottom: 50px;
      }

      &:last-child {
        left: 0px;
        bottom: 0px;
        width: 100%;
        opacity: 0.7;
      }
    }
  }
`;

export const LegalDetailsSection = styled.div`
  & > div {
    & > div {
      min-width: calc(33.333% - 30px);
      max-width: calc(33.333% - 30px);
      margin-bottom: 32px;

      p {
        font-weight: 500;

        &:first-child {
          font-size: 12px;
          opacity: 0.35;
        }

        &:last-child {
          font-size: 16px;
        }
      }
    }
  }
`;

export const DirectorsSection = styled.div`
  .directors-carousel-item {
    position: relative;
    padding-bottom: 50px;

    h3 {
      font-size: 14px;
    }

    .designation {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.58;
      opacity: 0.7;
    }

    .date {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 12px;
      opacity: 0.35;
    }
  }
`;

export const ContactIconList = styled.ul`
  display: block;
  list-style: none;
  padding: 0;
  margin: -4px;

  li {
    display: inline-block;
    margin: 4px;

    a,
    button {
      display: block;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey} !important;
      padding: 7px;
      pointer-events: all;

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.lightGrey};
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.is-brief {
      &:last-child {
        a {
          height: auto;
          width: auto;
          border: unset !important;
          padding: 0px;
          transform: translateY(-3px);
        }
      }
    }
  }
`;
