import actionCreator from 'utils/actionCreator';

export const actions = actionCreator('app', [
  'SEARCH_RECORDS_REQUEST',
  'SEARCH_RECORDS_SUCCESS',
  'SEARCH_RECORDS_LOADING',
  'CONNECTED_SUPPLIERS_REQUEST',
  'CONNECTED_SUPPLIERS_SUCCESS',
  'SUPPORT_REQUEST',
  'SUPPORT_REQUEST_SUCCESS',
  'SUPPORT_REQUEST_ERROR',
]);

export function searchRecordsRequest(payload) {
  return {
    type: actions.SEARCH_RECORDS_REQUEST,
    payload,
  };
}

export function searchRecordsSuccess(payload) {
  return {
    type: actions.SEARCH_RECORDS_SUCCESS,
    payload,
  };
}

export function searchRecordsLoading(payload) {
  return {
    type: actions.SEARCH_RECORDS_LOADING,
    payload,
  };
}

export function connectedSuppliersRequest(payload) {
  return {
    type: actions.CONNECTED_SUPPLIERS_REQUEST,
    payload,
  };
}

export function connectedSuppliersSuccess(payload) {
  return {
    type: actions.CONNECTED_SUPPLIERS_SUCCESS,
    payload,
  };
}

export function supportRequest(payload) {
  return {
    type: actions.SUPPORT_REQUEST,
    payload,
  };
}

export function supportRequestSuccess(payload) {
  return {
    type: actions.SUPPORT_REQUEST_SUCCESS,
    payload,
  };
}

export function supportRequestError(payload) {
  return {
    type: actions.SUPPORT_REQUEST_ERROR,
    payload,
  };
}
