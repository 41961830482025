import { combineReducers } from 'redux';
import app from 'modules/App/store/reducer';
import user from 'modules/Users/store/reducer';
import listing from 'modules/Listing/store/reducer';
import dashboard from 'modules/Dashboard/store/reducer';

export default () =>
  combineReducers({
    app,
    user,
    listing,
    dashboard,
  });
