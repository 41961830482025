import styled from 'styled-components';

export const HeaderContainer = styled.nav`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 9.5px 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  background: ${({ isDark }) =>
    isDark ? 'linear-gradient(to bottom, #006ac1, #053f6f)' : '#ffffff'};

  .close-menu-bg-button {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    cursor: default;
  }

  .nav-row {
    margin: 0;
  }

  .menu-column {
    a {
      color: ${({ isDark }) => (isDark ? '#ffffff' : '')};
      border-color: ${({ isDark }) => (isDark ? '#ffffff' : '')};

      &:hover {
        ${/* eslint-disable indent */
        ({ isDark, theme }) =>
          isDark
            ? `background-color: #ffffff; color: ${theme.colors.blue}`
            : ''}
        ${`/* eslint-enable indent */`}
      }

      &.no-underline {
        border: 1px solid transparent !important;
        transition: all 0.2s;
        border-radius: 3px;

        &:hover {
          background-color: transparent;
          color: ${({ isDark, theme }) =>
            isDark ? '#ffffff' : theme.colors.blueHover};
          border: 1px solid #ffffff !important;
        }
      }
    }

    &__large {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &__small {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &.is-supplier {
      a,
      button {
        color: initial;
        opacity: 0.9;
        border: 0;

        &:hover {
          border: 0;
          text-decoration: 0;
          opacity: 1;
        }
      }

      button {
        margin-left: 20px;
      }
    }
  }

  .mobile-menu-toggle {
    svg path {
      stroke: ${({ isDark, theme }) =>
        isDark ? '#ffffff' : theme.colors.black};
    }
  }

  .toggle-mobile-search {
    display: none;
  }

  .supplier-menu-link {
    color: ${({ isDark }) => (isDark ? '#ffffff' : '#000000')} !important;

    &:hover {
      background: transparent;
      background-color: transparent !important;
    }
  }

  @media (max-width: 767px) {
    .search-form-column {
      position: fixed;
      top: 80px;
      left: 20px;
      right: 20px;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      transition: opacity 0.2s;

      & > div {
        text-align: right;
      }

      &.mobile-active {
        opacity: 1;
        pointer-events: all;
        z-index: 1;
      }
    }

    .toggle-mobile-search {
      display: inline-block;
      margin-left: auto;
      margin-right: 30px;
    }
  }

  @media (max-width: 480px) {
    .search-form-column {
      left: 5px;
      right: 5px;
      margin: 0;

      & > div {
        text-align: center;
        white-space: nowrap;
      }
    }
  }
`;

export const MobileMenu = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  z-index: 11;

  @media (max-width: 767px) {
    display: block;
    transition: all 0.2s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};

    .container {
      transition: inherit;
      transform: ${({ isActive }) =>
        isActive ? 'translateX(0)' : 'translateX(140%)'};
    }
  }

  .menu-close {
    position: absolute;
    top: 24px;
    left: 24px;

    svg {
      border: 1.5px solid #ffffff;
      border-radius: 50%;

      path {
        stroke: #ffffff;
      }
    }
  }

  .container {
    position: relative;
    margin-left: 74px;
    background-color: #ffffff;
    height: 100%;
    padding: 15px 15px 55px;
    overflow: auto;

    .profile-mobile,
    .menu-profile {
      position: absolute;
      bottom: 32px;
      left: 32px;
      width: calc(100% - 64px);
    }

    .menu-profile {
      a {
        width: calc(50% - 6px);
        text-align: center;
      }
    }

    .profile-mobile-details {
      margin-left: 10px;
      line-height: 1;

      button {
        color: ${({ theme: { colors } }) => colors.blue};
      }
    }

    .main-menu {
      list-style: none;
      padding: 0 16px;
      margin: 40px 0 0;

      li {
        padding: 0;
        margin: 0 0 16px;
      }
    }

    .social-menu {
      list-style: none;
      padding: 0 12px;
      margin: 0;

      li {
        display: inline-block;
        margin: 0 30px 0 0;

        a {
          border: 0;

          svg {
            fill: ${({ theme: { colors } }) => colors.blue};
          }

          &:hover {
            svg {
              fill: ${({ theme: { colors } }) => colors.blueHover};
            }
          }
        }
      }
    }
  }
`;

export const User = styled.div`
  display: inline-block;
  position: relative;
  z-index: 1;

  .name {
    color: ${({ isDark, theme }) => (isDark ? '#ffffff' : theme.colors.black)};
    font-size: 14px;
    opacity: 0.7;
    margin-left: 8px;
    vertical-align: middle;
  }

  svg {
    margin-top: 3px;
    margin-left: 8px;
    opacity: ${({ isDark }) => (isDark ? 0.75 : 0.35)};
    fill: ${({ isDark, theme }) => (isDark ? '#ffffff' : theme.colors.black)};
  }
`;

export const UserInitials = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isDark, theme }) =>
    isDark ? '#ffffff' : theme.colors.grey};
  border-radius: 50px;
  padding: 1px;
  color: ${({ isDark, theme }) => (isDark ? theme.colors.grey : '#ffffff')};
  height: 32px;
  width: 32px;
  font-size: 11px;
  letter-spacing: -1px;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
`;

export const UserMenu = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: ${({ theme: { shadow } }) => shadow};
  border: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  min-width: 130px;
  padding: 5px 0;
  opacity: 0;
  transform: translateY(-5px);
  transition: ${({ theme: { transition } }) => transition};
  pointer-events: none;

  &.active {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: all;
  }

  li {
    margin: 0;
    padding: 0;

    button,
    a {
      display: block;
      text-align: left;
      width: 100%;
      padding: 5px 13px;
      border: 0 !important;
      color: ${({ theme: { colors } }) => colors.black} !important;
      font-size: 14px;
      line-height: 1.5;

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.lightGrey};
      }
    }
  }
`;
