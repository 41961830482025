import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import getImage from 'utils/getImage';

const SupplierImageItem = ({ supplier, onClick }) => (
  <Button
    type="button"
    className="ps-tag"
    onClick={onClick}
    style={{
      backgroundImage: `url(${getImage(
        supplier.image_id,
      )}), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0.1) 49%, rgba(0, 0, 0, 0.65) 70%, rgba(0, 0, 0, 0.85) 88%, rgba(0, 0, 0, 0.9))`,
    }}>
    <span>{supplier.main_psname}</span>
  </Button>
);

SupplierImageItem.propTypes = {
  supplier: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SupplierImageItem;
