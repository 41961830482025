import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  body {
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  main,
  #root {
    overflow: hidden;
    max-width: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  span {
    margin: 0;
    line-height: 1.6;
  }

  h1 {
    font-weight: 500;
    font-size: 3.4em;
    line-height: 1.1;

    @media (max-width: 767px) {
      font-size: 2em;
    }

    @media (max-width: 480px) {
      font-size: 1.75em;
    }
  }

  h2 {
    font-size: 2em;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 1.6em;
    }
  }

  h3 {
    font-size: 1.5em;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 1.25em;
    }
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  a {
    color: ${({ theme: { colors } }) => colors.blue};
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s;

    &:hover {
      border-bottom: 1px solid ${({ theme: { colors } }) => colors.blue};
    }

    &:focus {
      outline: 0;
      color: ${({ theme: { colors } }) => colors.blueHover};
    }

    &.no-underline {
      border: 0 !important;
      text-decoration: none !important;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  textarea,
  select {
    display: block;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
    width: 100%;
    transition: border 0.2s;

    &::placeholder {
      color: ${({ theme: { colors } }) => colors.grey};
    }

    &:focus {
      outline: 0;
      border-color: ${({ theme: { colors } }) => colors.grey};
    }

    &.small-input {
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  textarea {
    resize: none;
  }

  button,
  input,
  textarea,
  select {
    font: 400 1em 'Poppins', sans-serif;;
  }

  [type='number'].hide-number-spin {
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  .center {
    text-align: center !important;
  }

  .left {
    text-align: left !important;
  }

  .right {
    text-align: right !important;
  }

  .bold {
    font-weight: 500;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .italic {
    font-style: italic;
  }

  .resend-otp-button {
    font-size: 14px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      color: ${({ theme: { colors } }) => colors.blue};
    }
  }

  .red {
    color: ${({ theme: { colors } }) => colors.red};
  }
`;
