import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

const ButtonIcon = ({ icon, fill }) => (
  <Icon
    name={icon}
    fill={fill || '#ffffff'}
    height="20px"
    width="20px"
    style={{ verticalAlign: 'middle', marginRight: 7 }}
  />
);

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  fill: PropTypes.string,
};

export default ButtonIcon;
