import { actions } from './actions';

const INITIAL_STATE = {
  suppliersSearch: {
    searchId: '',
    searchKeyword: '',
    searchCities: [],
    searchResults: [],
  },
  searchLoading: false,
  cities: [],
  tags: [],
  psTags: [],
  comparisionSuppliers: [],
  partners: [],
};

export default function appReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case actions.SUPPLIERS_SEARCH_SUCCESS:
      return { ...state, suppliersSearch: payload };
    case actions.TOGGLE_SEARCH_LOADING:
      return { ...state, searchLoading: payload };
    case actions.CITIES_SUCCESS:
      return { ...state, cities: payload };
    case actions.TAGS_SUCCESS:
      return { ...state, tags: payload };
    case actions.PS_TAGS_SUCCESS:
      return { ...state, psTags: payload };
    case actions.COMPARISION_SUPPLIER_SUCCESS:
      return { ...state, comparisionSuppliers: payload };
    case actions.PARTNERS_SUCCESS:
      return { ...state, partners: payload };
    default:
      return state;
  }
}
