import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';

const common = `
  background-color: rgba(0, 0, 0, 0);
  padding: 7px 15px;
  color: ${theme.colors.blue};
  font-size: 16px;
  line-height: 1.2;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid ${theme.colors.blue};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #ffffff;
    background-color: ${theme.colors.blue};
    border-color: ${theme.colors.blue};
  }

  &:focus {
    color: #ffffff;
    background-color: ${theme.colors.blueHover};
    outline: unset;
    border-color: ${theme.colors.blueHover};
  }

  svg {
    vertical-align: middle;
  }
`;

const inverted = `
  color: ${theme.colors.blue};
  background-color: #ffffff;
  border-color: #ffffff;

  &:hover,
  &:focus {
    color: ${theme.colors.blue};
    background-color: ${theme.colors.lightGrey};
    border-color: #ffffff;
  }
`;

const darkened = `
  background-color: ${theme.colors.blue};
  color: #ffffff;
  border-color: ${theme.colors.blue};

  &:hover,
  &:focus {
    background-color: ${theme.colors.blueHover};
    border-color: ${theme.colors.blueHover};
  }
`;

const greyed = `
  color: ${theme.colors.black};
  background-color: ${theme.colors.lightGrey};
  border-color: ${theme.colors.lightGrey};

  &:hover,
  &:focus {
    color: ${theme.colors.black};
    background-color: #ffffff;
    border-color: ${theme.colors.black};
  }
`;

export const SubmitButton = styled.button`
  ${common}
  ${({ invert }) => invert && inverted}
  ${({ dark }) => dark && darkened}
  ${({ grey }) => grey && greyed}
  ${({ rounded }) => rounded && `border-radius: 50px;`}
  ${({ fluid }) =>
    fluid &&
    `
    width: 100%;
  `}

  &:disabled {
    background-color: ${({ theme: { colors } }) => colors.grey};
    border-color: ${({ theme: { colors } }) => colors.grey};
    cursor: not-allowed;
  }
`;

export const AnchorButton = styled(Link)`
  ${common}
  ${({ invert }) => invert && inverted}
  ${({ dark }) => dark && darkened}
  display: inline-block;

  ${({ fluid }) =>
    fluid &&
    `
    width: 100%;
  `}

  &:disabled {
    background-color: ${({ theme: { colors } }) => colors.grey};
    border-color: ${({ theme: { colors } }) => colors.grey};
    cursor: not-allowed;
  }
`;

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
