export default {
  auth: `${process.env.REACT_APP_API_HOST}/auth`,
  users: `${process.env.REACT_APP_API_HOST}/users`,
  getUserByToken: `${process.env.REACT_APP_API_HOST}/users/me`,
  generateOTP: `${process.env.REACT_APP_API_HOST}/otp`,
  forgotPassword: `${process.env.REACT_APP_API_HOST}/users/forgotpw`,
  changePassword: `${process.env.REACT_APP_API_HOST}/users/changepw`,
  checkUser: `${process.env.REACT_APP_API_HOST}/users/checkuser`,
  checkUserPhone: `${process.env.REACT_APP_API_HOST}/users/phonenumber`,
  getCities: `${process.env.REACT_APP_API_HOST}/suppliers/searchcities/all`,
  getTags: `${process.env.REACT_APP_API_HOST}/pstagging/getalltags`,
  getPSTags: `${process.env.REACT_APP_API_HOST}/pstagging`,
  searchSupplier: `${process.env.REACT_APP_API_HOST}/suppliers/search`,
  getSupplier: `${process.env.REACT_APP_API_HOST}/suppliers`,
  getSupplier2: `${process.env.REACT_APP_API_HOST}/suppliers/just_supplier`,
  supplierName: `${process.env.REACT_APP_API_HOST}/suppliers/name`,
  getMedia: `${process.env.REACT_APP_API_HOST}/medias`,
  getSearchRecords: `${process.env.REACT_APP_API_HOST}/searchrecords`,
  getSearchRecordsByUser: `${process.env.REACT_APP_API_HOST}/searchrecords/user`,
  connectedSuppliers: `${process.env.REACT_APP_API_HOST}/connectedsuppliers`,
  getConnectedSuppliers: `${process.env.REACT_APP_API_HOST}/connectedsuppliers/user`,
  contactUs: `${process.env.REACT_APP_API_HOST}/contactus`,
  getPartners: `${process.env.REACT_APP_API_HOST}/cppartner`,
  getSupplierCities: `${process.env.REACT_APP_API_HOST}/suppliers/searchcities/all`,
  getAllCities: `${process.env.REACT_APP_API_HOST}/suppliers/searchcities/address-roc`,
  getAllClients: `${process.env.REACT_APP_API_HOST}/clientdetails`,
};
