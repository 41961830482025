import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  min-height: 100vh;
  background-image: ${({ isSupplier }) =>
    isSupplier
      ? `url('/assets/img/signup-supplier-bg.png')`
      : `url('/assets/img/signup.png')`};
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;

  @media (max-width: 979px) {
    align-items: flex-start;
    padding-top: 35px;
    background: ${({ theme: { colors } }) => colors.lightGrey};
  }

  & > div {
    & > div:not(.client-modal) {
      max-width: 458px;
      margin-left: 40%;

      @media (max-width: 979px) {
        margin-left: 0;
      }
    }
  }

  .comfirmation {
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.grey};
    margin-top: 26px;
  }

  .progress {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      margin-right: 10px;
    }

    .loader {
      position: relative;

      .circle {
        width: 30px;
        height: 30px;
        border: 5px solid rgba(1, 7, 9, 0.07);
        border-radius: 50px;
        transform: rotate(45deg);
      }

      &.step-1 {
        .circle {
          border-top-color: ${({ theme: { colors } }) => colors.greenBlue};
        }
      }

      &.step-2 {
        .circle {
          border-top-color: ${({ theme: { colors } }) => colors.greenBlue};
          border-right-color: ${({ theme: { colors } }) => colors.greenBlue};
        }
      }

      &.step-3 {
        .circle {
          border-top-color: ${({ theme: { colors } }) => colors.greenBlue};
          border-right-color: ${({ theme: { colors } }) => colors.greenBlue};
          border-bottom-color: ${({ theme: { colors } }) => colors.greenBlue};
        }
      }

      &.step-4 {
        .circle {
          border-color: ${({ theme: { colors } }) => colors.greenBlue};
        }
      }
    }
  }

  .register-cards {
    & > div {
      margin: 0 -9px;

      & > div {
        margin: 0 9px;

        & > button {
          position: relative;
          display: block;
          border-radius: 4px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
          background-color: #ffffff;
          border: 1px solid transparent;
          overflow: hidden;
          transition: all 0.2s;

          p {
            text-align: center;
            font-size: 16px;
            padding: 12px 5px;
            transition: inherit;
          }

          &:hover,
          &.is-selected {
            border: 1px solid ${({ theme: { colors } }) => colors.blue};

            p {
              color: ${({ theme: { colors } }) => colors.blue};
            }
          }

          &.is-selected {
            &::before {
              content: '';
              position: absolute;
              top: 10px;
              right: 10px;
              height: 24px;
              width: 24px;
              background-image: url('/assets/img/checked.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
    }
  }

  .phone-container {
    position: relative;

    input {
      padding-left: 50px;
    }

    &::before {
      content: '+91';
      position: absolute;
      left: 13px;
      top: 11px;
    }
  }

  .form-separator {
    opacity: 0.06;
    background-color: ${({ theme: { colors } }) => colors.black};
    height: 4px;
    margin: 30px -40px 25px;
  }

  .client-images {
    img {
      display: inline;
      max-width: 105px;
      border-radius: 3px;
      border: solid 2px ${({ theme: { colors } }) => colors.lightGrey};
      margin: 0 20px 20px 0;
      vertical-align: top;
    }
  }
`;
