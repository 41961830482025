import styled from 'styled-components';

export const Main = styled.footer`
  position: relative;
  background-color: #041727;
  padding: 28px 0;
  color: #ffffff;

  & > div {
    & > div {
      @media (max-width: 979px) {
        flex-wrap: wrap;

        & > div {
          min-width: 33.33%;
          margin-bottom: 20px;

          &:nth-child(4) {
            margin-left: auto;
          }

          &:nth-child(5) {
            h5 {
              text-align: left !important;
            }

            ul {
              margin-left: -10px;
            }
          }
        }
      }

      @media (max-width: 767px) {
        & > div {
          min-width: 47%;

          &:nth-child(1) {
            min-width: 100%;
          }

          &:nth-child(4) {
            margin-left: 0;
          }
        }
      }
    }
  }

  a {
    color: #ffffff;
  }

  h5 {
    margin: 10px 0;
    font-size: 16px;
    opacity: 0.6;
    font-weight: normal;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
      margin-bottom: 8px;

      a {
        font-size: 16px;
        color: ${({ theme: { colors } }) => colors.lightGrey};
        border-bottom: 0 !important;
      }
    }

    &.social {
      li {
        display: inline-block;
        margin: 0 8px;

        svg {
          fill: #ffffff;
        }
      }
    }
  }

  .footer-logo {
    span {
      color: #ffffff !important;
      opacity: 0.6;
    }
  }
`;
