import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #fafafa;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme: { colors } }) => colors.darkBlue};
    height: 100%;
    width: 519px;

    @media (max-width: 767px) {
      content: none;
    }
  }

  & > div {
    position: relative;
    z-index: 1;
    height: 100vh;
    padding: 20px 0;

    & > div {
      height: 100%;

      & > div {
        &:first-child {
          img {
            width: 565px;
          }
        }

        @media (max-width: 767px) {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  .logo-link {
    display: block;
    text-align: center;
    margin-bottom: 74px;

    &:hover {
      border: 0;
    }
  }

  h2 {
    font-size: 22px;
  }

  .form-box {
    max-width: 450px;
    padding: 24px;
    border-radius: 4px;
    background-color: #ffffff;
    margin: auto;
    border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  }

  form {
    padding: 30px 0 100px;
    max-width: 370px;
    margin: 0 auto;

    input {
      margin-bottom: 15px;
    }

    .error {
      color: red;
      font-size: 0.9em;
    }
  }

  .signup-button {
    width: 100%;
    text-align: center;
  }
`;
