import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  min-height: 100vh;
  padding: 30px 0px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 40px;
  }

  a {
    padding: 15px 30px;
  }
`;
