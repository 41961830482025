import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

const ForgotPasswordForm = ({
  input,
  inputChangeHandler,
  formSubmitHandler,
  step,
  getError,
}) => (
  <>
    <p>
      {step === 0 && 'Please enter your registered email address.'}
      {step === 1 &&
        'Success! An email with your account details is sent to your registered email account.'}
    </p>
    <form onSubmit={formSubmitHandler}>
      {step === 0 && (
        <>
          <input
            type="email"
            name="email"
            id="email"
            value={input}
            onChange={inputChangeHandler}
            placeholder="Enter email address"
            disabled={step === 1}
          />
          {getError('email') && (
            <div>
              <span className="error">{getError('email').message}</span>
            </div>
          )}
        </>
      )}
      <Button type="submit" dark>
        {step === 0 && 'Continue'}
        {step === 1 && 'Take me to login'}
      </Button>
    </form>
  </>
);

ForgotPasswordForm.propTypes = {
  input: PropTypes.string.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  getError: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
