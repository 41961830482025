import styled from 'styled-components';

export const Banner = styled.header`
  padding: 125px 0;
  background-image: url('/assets/img/contact-us-header.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  h1 {
    max-width: 777px;
    font-size: 34px;
    color: #ffffff;
    margin: 0 auto;
    line-height: 1.5;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
`;

export const Story = styled.section`
  padding: 55px 0;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};

  h2 {
    position: relative;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    font-size: 16px;
    margin-bottom: 34px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      background-color: ${({ theme: { colors } }) => colors.darkBlue};
    }

    span {
      position: relative;
      z-index: 1;
      padding-right: 8px;
      background-color: ${({ theme: { colors } }) => colors.lightGrey};
    }
  }

  p {
    opacity: 0.7;
    margin-bottom: 24px;
    max-width: 663px;
  }

  img {
    width: 318px;
    height: 311px;
    object-fit: cover;
  }

  @media (max-width: 979px) {
    .story-row {
      flex-direction: column;
    }
  }
`;

export const Values = styled.section`
  background-color: ${({ theme: { colors } }) => colors.darkBlue};
  background-image: url('/assets/img/pattern.png');
  background-position: top;
  background-size: cover;
  background-repeat: repeat-y;
  padding: 65px 0 10px;

  h2 {
    position: relative;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 68px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100px;
      transform: translateY(-50%);
      height: 1px;
      width: calc(100% - 100px);
      background-color: #ffffff;
      opacity: 0.5;
    }

    span {
      position: relative;
      z-index: 1;
      padding-right: 8px;
    }
  }

  .values {
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    & > div {
      max-width: calc(50% - 30px);
      min-width: calc(50% - 30px);
      margin-bottom: 56px;

      @media (max-width: 767px) {
        max-width: calc(100% - 30px);
        min-width: calc(100% - 30px);
      }

      img {
        display: inline-block;
        width: 72px;
        height: 72px;
        vertical-align: middle;
        margin-right: 16px;
      }

      span {
        display: inline-block;
        color: #ffffff;
        vertical-align: middle;
        width: calc(100% - 88px);
        max-width: 330px;
      }
    }
  }
`;

export const Presence = styled.section`
  padding: 90px 0;

  h2 {
    font-size: 22px;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    margin-bottom: 53px;
  }

  .numbers {
    margin-bottom: 48px;

    span {
      &:first-child {
        font-size: 32px;
        font-weight: 500;
        line-height: 1.2;
      }

      &:last-child {
        font-size: 18px;
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 767px) {
    .presence-row {
      flex-direction: column;

      img {
        width: 300px;
        margin-bottom: 50px;
      }
    }
  }
`;

export const Partner = styled.section`
  background-color: ${({ theme: { colors } }) => colors.dullYellow};

  .card {
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px ${({ theme: { colors } }) => colors.gold};
    background-color: #ffffff;
    padding: 19px 32px;
    transform: translateY(-50px);

    @media (max-width: 979px) {
      padding: 19px 24px;

      & > div {
        flex-direction: column;
        margin: 0;

        & > div {
          margin: 0;
        }

        button {
          margin-top: 15px;
        }
      }
    }

    h3 {
      font-size: 22px;
    }

    p {
      opacity: 0.7;
      font-size: 14px;
      max-width: 539px;
    }
  }
`;
