import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';

const Listing = lazy(() => import('modules/Listing/container/Listing'));

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 20,
  height: 'calc(100vh - 320px)',
};

const HomePage = () => (
  <DashboardLayout>
    <Helmet title="Search Suppliers | EasyProcur" />
    <Suspense fallback={<Loading style={loadingStyle} />}>
      <Listing />
    </Suspense>
  </DashboardLayout>
);

export default HomePage;
