import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: -200px;
  left: 15px;
  z-index: 90009;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.black};
  padding: 10px 15px;
  box-shadow: ${({ theme: { shadow } }) => shadow};
  border-radius: 3px;
  color: #ffffff;
  max-width: 400px;
  pointer-events: none;
  transition: ${({ theme: { transitionOut } }) => transitionOut};

  &.show {
    bottom: 15px;
    opacity: 1;
    pointer-events: all;
    transition: ${({ theme: { transitionIn } }) => transitionIn};
  }

  button {
    margin-left: 8px;
    border-radius: 50%;
    padding: 4px;
    transition: all 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:focus {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  span {
    letter-spacing: 0.4px;
    line-height: 1.4;
  }
`;
