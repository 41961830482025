import React from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/Carousel';

import { TestimonialSection } from './SupplierDetails.styled';

const Testimonials = ({ supplier }) =>
  supplier.testimonial && supplier.testimonial.length > 0 ? (
    <TestimonialSection id="more">
      <h4>
        <span>Testimonials</span>
      </h4>
      <Carousel
        itemWidth={200}
        items={supplier.testimonial.map((i) => (
          <div key={i._id} className="testimonial-carousel-item">
            <p>{i.message}</p>
            <p>{`${i.person} - ${i.designation}, ${i.company}`}</p>
          </div>
        ))}
      />
    </TestimonialSection>
  ) : (
    <></>
  );

Testimonials.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default Testimonials;
