/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';

import getImage from 'utils/getImage';

import Container from './LightboxCarousel.styled';

const LightboxCarousel = ({ videos = [], images = [], className, style }) => {
  const [step, setStep] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [items, setItems] = useState([]);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const containerRef = useRef();

  function stepClickHandler(e) {
    const { type } = e.target.dataset;

    if (type === 'prev' && step > 0) {
      const changeTo = items[step - 1].type;

      setTranslate(changeTo === 'video' ? translate - 190 : translate - 110);
      setStep(step - 1);
    }

    if (type === 'next' && step < items.length - 1) {
      const changeTo = items[step].type;

      setTranslate(changeTo === 'video' ? translate + 190 : translate + 110);
      setStep(step + 1);
    }
  }

  function openLightBox(e) {
    const { id } = e.target.dataset;

    setStep(items.findIndex((i) => i.id === id));
    setShowLightbox(true);
  }

  function closeLightBox() {
    setShowLightbox(false);
  }

  function clickHandler(e) {
    if (
      (e.target.classList.contains('lightbox') &&
        e.target.classList.contains('active')) ||
      e.target.className === 'lightbox-content'
    ) {
      closeLightBox();
    }
  }

  function keyDownHandler(e) {
    if (showLightbox && (e.key === 'Escape' || e.key === 'Esc'))
      closeLightBox();
  }

  useEffect(() => {
    let all = [];

    if (videos && videos.length > 0) {
      videos.forEach((i) => all.push({ type: 'video', id: i }));
      all = all.map((i) => {
        const youtubeLink = new URL(i.id).searchParams;
        const id = youtubeLink.get('v');

        return {
          ...i,
          thumbnail: `https://img.youtube.com/vi/${id}/0.jpg`,
          ytID: id,
        };
      });
    }
    if (images && images.length > 0)
      images.forEach((i) =>
        all.push({ type: 'image', id: i, thumbnail: getImage(i) }),
      );
    setItems(all);
  }, [videos, images]);

  useEffect(() => {
    const nav = document.getElementById('nav');
    const filtersRow = document.getElementById('filters-row');
    const listingSection = document.getElementById('listing-section');

    if (nav) nav.style.zIndex = showLightbox ? '1' : '10';
    if (filtersRow) filtersRow.style.zIndex = showLightbox ? '1' : '9';
    if (listingSection) listingSection.style.zIndex = showLightbox ? '1' : '0';
    document.body.style.overflow = showLightbox ? 'hidden' : 'auto';
  }, [showLightbox]);

  useEffect(() => {
    if (containerRef.current && items.length > 0 && (step === 0 || step)) {
      setTimeout(() => {
        if (containerRef.current?.getBoundingClientRect) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const buttons = containerRef.current.querySelectorAll(
            '.items > button',
          );
          const lastButtonRect = buttons[
            buttons.length - 1
          ].getBoundingClientRect();

          setIsScrolledToRight(containerRect.right > lastButtonRect.right);
        }
      }, 300);
    }
  }, [items, step]);

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => window.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => window.removeEventListener('keydown', keyDownHandler);
  });

  return (
    <Container ref={containerRef} className={className} style={style}>
      <div className="items">
        {items.length > 0 &&
          items.map((i) => (
            <Button
              key={i.id}
              type="button"
              data-id={i.id}
              onClick={openLightBox}
              style={{
                minWidth: i.type === 'video' ? 175 : 95,
                backgroundImage: `url(${i.thumbnail})`,
                transform: `translateX(-${translate}px)`,
              }}>
              {i.type === 'video' && <Icon name="play" fill="#ffffff" />}
            </Button>
          ))}
      </div>
      {items.length > 3 && (
        <ul className="toggle-box">
          <li>
            <Button
              type="button"
              data-type="prev"
              disabled={step === 0}
              className="chevron-button"
              onClick={stepClickHandler}>
              <Icon name="chevron" />
            </Button>
          </li>
          <li>
            <Button
              type="button"
              data-type="next"
              className="chevron-button"
              disabled={isScrolledToRight || step === items.length - 1}
              onClick={stepClickHandler}>
              <Icon name="chevron" rotate="180deg" />
            </Button>
          </li>
        </ul>
      )}
      <div className={`lightbox${showLightbox ? ' active' : ''}`}>
        <div className="lightbox-content">
          <Button
            type="button"
            onClick={closeLightBox}
            className="close-lightbox">
            <Icon name="close" />
          </Button>
          <Row>
            <Column>
              <Button
                type="button"
                className="chevron-button"
                data-type="prev"
                disabled={step === 0}
                onClick={stepClickHandler}>
                <Icon
                  name="chevron"
                  fill="#ffffff"
                  height="24px"
                  width="24px"
                />
              </Button>
            </Column>
            <Column flex="8">
              {items.length > 0 && (
                <>
                  {showLightbox && items[step].type === 'video' && (
                    <iframe
                      title="yt-embed"
                      src={`https://www.youtube.com/embed/${items[step].ytID}`}
                    />
                  )}
                  {items[step].type === 'image' && (
                    <img src={items[step].thumbnail} alt="i" />
                  )}
                </>
              )}
            </Column>
            <Column>
              <Button
                type="button"
                className="chevron-button"
                data-type="next"
                disabled={step === items.length - 1}
                onClick={stepClickHandler}>
                <Icon
                  name="chevron"
                  rotate="180deg"
                  fill="#ffffff"
                  height="24px"
                  width="24px"
                />
              </Button>
            </Column>
          </Row>
        </div>
      </div>
    </Container>
  );
};

LightboxCarousel.propTypes = {
  videos: PropTypes.array,
  images: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LightboxCarousel;
