import React from 'react';

export const getIcons = () => [
  {
    id: 1,
    name: 'warning',
    svg: (
      <>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </>
    ),
    viewBox: '0 0 24 24',
    height: '24px',
    width: '24px',
  },
  {
    id: 2,
    name: 'search',
    svg: (
      <path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" />
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 3,
    name: 'location',
    svg: (
      <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
    ),
    viewBox: '0 0 384 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 4,
    name: 'globe',
    svg: (
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z" />
    ),
    viewBox: '0 0 496 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 5,
    name: 'call',
    svg: (
      <path d="M497.39 361.8l-112-48a24 24 0 00-28 6.9l-49.6 60.6A370.66 370.66 0 01130.6 204.11l60.6-49.6a23.94 23.94 0 006.9-28l-48-112A24.16 24.16 0 00122.6.61l-104 24A24 24 0 000 48c0 256.5 207.9 464 464 464a24 24 0 0023.4-18.6l24-104a24.29 24.29 0 00-14.01-27.6z" />
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 6,
    name: 'email',
    svg: (
      <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 7,
    name: 'download',
    svg: (
      <path d="M0 2v21h24V5H9L7.219 2H0zm2 5h20v14H2V7zm9 2v4H9.25L12 16.75 14.75 13H13V9h-2zm-4 9v1h10v-1H7z" />
    ),
    viewBox: '0 0 24 24',
    height: '24px',
    width: '24px',
  },
  {
    id: 8,
    name: 'share',
    svg: (
      <path d="M14.395 7.053C1.763 7.053.5 22.21.5 22.21s2.526-6.316 13.895-6.316v5.052L24.5 11.231 14.395 2v5.053z" />
    ),
    viewBox: '0 0 25 24',
    height: '24px',
    width: '25px',
  },
  {
    id: 9,
    name: 'chevron',
    svg: (
      <path d="M8.333 1.522l-1-.855-6 6.518-.396.428.396.428 6 6.518 1-.855L2.73 7.613z" />
    ),
    viewBox: '0 0 8 15',
    height: '15px',
    width: '15px',
  },
  {
    id: 10,
    name: 'directions',
    svg: (
      <path d="M0 0l1.063 6.667 1.875-1.875 1.666 1.666a6.812 6.812 0 011.458 2.146c.266-1 .735-2.005 1.334-2.937-.266-.401-.589-.73-.854-1.063L4.833 2.896l1.834-1.833L0 0zm16 0L9.333 1.063l1.854 1.854-1.666 1.666a9.717 9.717 0 00-2.854 6.875V16h2.666v-4.542c0-1.849.732-3.627 2.084-4.979l1.666-1.667 1.854 1.855L16 0z" />
    ),
    viewBox: '0 0 16 16',
    height: '16px',
    width: '16px',
  },
  {
    id: 11,
    name: 'play',
    svg: (
      <path d="M282.824 0C126.877 0 0 126.877 0 282.824s126.877 282.824 282.824 282.824 282.824-126.877 282.824-282.824S438.771 0 282.824 0zm-70.706 424.233V141.411l212.118 141.41z" />
    ),
    viewBox: '0 0 565.648 565.648',
    height: '24px',
    width: '24px',
  },
  {
    id: 12,
    name: 'pencil',
    svg: (
      <path d="M17.414 0L3.074 14.34 0 24l9.66-3.075L24 6.586s-.11-2.306-2.195-4.39C19.719.11 17.414 0 17.414 0zm.411 1.784c1.177.224 2.114.722 2.837 1.464.723.742 1.231 1.727 1.554 2.927l-1.99 1.99-4.39-4.392 1.578-1.578.411-.411zM4.285 15.439c.014.003 1.32.334 2.632 1.644 1.427 1.318 1.646 2.526 1.646 2.526l.047.056-3.895 1.254-1.643-1.642 1.214-3.838z" />
    ),
    viewBox: '0 0 24 24',
    height: '24px',
    width: '24px',
  },
  {
    id: 13,
    name: 'tick-done',
    svg: (
      <path d="M32 0C14.364 0 0 14.364 0 32s14.364 32 32 32 32-14.364 32-32S49.636 0 32 0zm0 5.818c14.489 0 26.182 11.693 26.182 26.182 0 14.489-11.693 26.182-26.182 26.182C17.511 58.182 5.818 46.489 5.818 32 5.818 17.511 17.511 5.818 32 5.818zM47.727 20.91L29.091 39.455l-9.546-9.546-4.181 4.182L27 45.727l2.09 2 2.092-2L51.818 25l-4.09-4.09z" />
    ),
    viewBox: '0 0 64 64',
    height: '24px',
    width: '24px',
  },
  {
    id: 14,
    name: 'close',
    svg: (
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M368 368L144 144M368 144L144 368"
      />
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 15,
    name: 'facebook',
    svg: (
      <path d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 00-1.96 1.961v15.803a1.96 1.96 0 001.96 1.96H36.57v39.876a1.96 1.96 0 001.96 1.96h16.352a1.96 1.96 0 001.96-1.96V54.287h14.654a1.96 1.96 0 001.96-1.96l.006-15.803a1.963 1.963 0 00-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 001.959-1.96V1.98A1.96 1.96 0 0072.089.02z" />
    ),
    viewBox: '0 0 96.124 96.124',
    height: '24px',
    width: '24px',
  },
  {
    id: 16,
    name: 'twitter',
    svg: (
      <path d="M512.002 97.211c-18.84 8.354-39.082 14.001-60.33 16.54 21.686-13 38.342-33.585 46.186-58.115a210.29 210.29 0 01-66.705 25.49c-19.16-20.415-46.461-33.17-76.674-33.17-58.011 0-105.042 47.029-105.042 105.039 0 8.233.929 16.25 2.72 23.939-87.3-4.382-164.701-46.2-216.509-109.753-9.042 15.514-14.223 33.558-14.223 52.809 0 36.444 18.544 68.596 46.73 87.433a104.614 104.614 0 01-47.577-13.139c-.01.438-.01.878-.01 1.321 0 50.894 36.209 93.348 84.261 103a105.245 105.245 0 01-27.674 3.687c-6.769 0-13.349-.66-19.764-1.888 13.368 41.73 52.16 72.104 98.126 72.949-35.95 28.176-81.243 44.967-130.458 44.967-8.479 0-16.84-.496-25.058-1.471 46.486 29.807 101.701 47.197 161.021 47.197 193.211 0 298.868-160.062 298.868-298.872 0-4.554-.104-9.084-.305-13.59 20.526-14.809 38.335-33.309 52.417-54.373z" />
    ),
    viewBox: '0 0 512.002 512.002',
    height: '24px',
    width: '24px',
  },
  {
    id: 17,
    name: 'youtube',
    svg: (
      <path d="M35.705 0s-22.35 0-27.94 1.47c-3 .824-5.47 3.295-6.294 6.354C0 13.412 0 25 0 25s0 11.646 1.47 17.176a8.95 8.95 0 006.296 6.295c5.647 1.53 27.94 1.53 27.94 1.53s22.352 0 27.94-1.47a8.95 8.95 0 006.296-6.297c1.47-5.588 1.47-17.175 1.47-17.175s.06-11.647-1.47-17.235a8.95 8.95 0 00-6.295-6.295C58.058 0 35.707 0 35.707 0zm-7.117 14.295L47.176 25 28.588 35.705v-21.41z" />
    ),
    viewBox: '0 0 71.412 50',
    height: '24px',
    width: '24px',
  },
  {
    id: 18,
    name: 'instagram',
    svg: (
      <>
        <path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z" />
        <path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z" />
        <circle cx="393.6" cy="118.4" r="17.056" />
      </>
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 19,
    name: 'linkedin',
    svg: (
      <path d="M430.117 261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707-25.473 0-40.632 17.142-47.301 33.724-2.432 5.928-3.058 14.179-3.058 22.477V420.56h-92.219s1.242-251.285 0-277.32h92.21v39.309c-.187.294-.43.611-.606.896h.606v-.896c12.251-18.869 34.13-45.824 83.102-45.824 60.673-.001 106.157 39.636 106.157 124.818zM52.183 9.558C20.635 9.558 0 30.251 0 57.463c0 26.619 20.038 47.94 50.959 47.94h.616c32.159 0 52.159-21.317 52.159-47.94-.606-27.212-20-47.905-51.551-47.905zM5.477 420.56h92.184V143.24H5.477v277.32z" />
    ),
    viewBox: '0 0 430.117 430.117',
    height: '24px',
    width: '24px',
  },
  {
    id: 20,
    name: 'compare',
    svg: (
      <path
        fillRule="evenodd"
        d="M12.5 3c-.77 0-1.445.426-1.781 1.063A3.487 3.487 0 0010 4c-1.133 0-2.02.738-2.781 1.375-.383.316-.762.602-1.063.813-.023.015-.043.015-.062.03A.976.976 0 005.5 6c-.55 0-1 .45-1 1 0 .25.098.48.25.656L1.656 17H.5s.898 4 5 4 5-4 5-4H9.344L6.25 7.656c.105-.117.148-.277.188-.437.105-.059.214-.117.312-.188.375-.258.727-.597 1.094-.906C8.582 5.512 9.332 5 10 5c.18 0 .348.031.5.063A2.004 2.004 0 0012.5 7a2.004 2.004 0 002-1.938c.152-.03.32-.062.5-.062.668 0 1.418.512 2.156 1.125.367.309.719.648 1.094.906.098.07.207.13.313.188.039.16.082.32.187.437L15.656 17H14.5s.898 4 5 4 5-4 5-4h-1.156L20.25 7.656c.152-.176.25-.406.25-.656 0-.55-.45-1-1-1a.97.97 0 00-.594.219c-.02-.016-.039-.016-.062-.032-.301-.21-.68-.496-1.063-.812C17.02 4.738 16.133 4 15 4c-.258 0-.496.016-.719.063A2.006 2.006 0 0012.5 3zm-7 5.563L8.313 17H2.686L5.5 8.562zm14 0L22.313 17h-5.625L19.5 8.562z"
      />
    ),
    viewBox: '0 0 25 24',
    height: '24px',
    width: '24px',
  },
  {
    id: 21,
    name: 'menu',
    svg: (
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="32"
        d="M80 160h352M80 256h352M80 352h352"
      />
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 22,
    name: 'eye',
    svg: (
      <>
        <path d="M508.129 245.942c-1.155-1.278-28.809-31.661-73.431-62.307C375.034 142.659 313.241 121 255.999 121c-57.241 0-119.034 21.659-178.698 62.635-44.622 30.646-72.275 61.029-73.43 62.307-5.162 5.712-5.162 14.404 0 20.115 1.155 1.278 28.809 31.661 73.431 62.307C136.965 369.341 198.759 391 255.999 391c57.241 0 119.034-21.659 178.698-62.635 44.622-30.646 72.275-61.029 73.431-62.307 5.161-5.712 5.161-14.404.001-20.116zM145.484 333.44c-40.404-19.392-78.937-47.81-109.449-77.434 17.675-17.131 57.684-52.53 109.471-77.478C130.074 200.472 121 227.195 121 256c-.001 28.791 9.064 55.502 24.484 77.44zM255.999 361c-57.848 0-105-47.06-105-105 0-57.897 47.103-105 105-105 57.854 0 105 47.066 105 105 .001 57.897-47.102 105-105 105zm110.495-27.528C381.926 311.528 391 284.805 391 256c0-28.791-9.065-55.502-24.484-77.44 40.404 19.392 78.937 47.81 109.449 77.434-17.674 17.131-57.684 52.53-109.471 77.478z" />
        <path d="M255.999 181c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75c.001-41.355-33.644-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.186 45-45 45z" />
      </>
    ),
    viewBox: '0 0 511.999 511.999',
    height: '15px',
    width: '15px',
  },
  {
    id: 23,
    name: 'copy',
    svg: (
      <>
        <path d="M408 480H184a72 72 0 01-72-72V184a72 72 0 0172-72h224a72 72 0 0172 72v224a72 72 0 01-72 72z" />
        <path d="M160 80h235.88A72.12 72.12 0 00328 32H104a72 72 0 00-72 72v224a72.12 72.12 0 0048 67.88V160a80 80 0 0180-80z" />
      </>
    ),
    viewBox: '0 0 512 512',
    height: '15px',
    width: '15px',
  },
  {
    id: 24,
    name: 'delete',
    svg: (
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#D81C13"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.333"
        d="M25.333 2H10.167L2 11.333l8.167 9.334h15.166a2.334 2.334 0 002.334-2.334v-14A2.334 2.334 0 0025.333 2zm-3.5 5.833l-7 7m0-7l7 7"
      />
    ),
    viewBox: '0 0 29 22',
    height: '19px',
    width: '26px',
  },
  {
    id: 25,
    name: 'remove',
    svg: (
      <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#FFF" />
        <path
          fill="#D81C13"
          fillRule="nonzero"
          d="M10 2a8 8 0 100 16 8 8 0 000-16zm2.968 9.832a.802.802 0 11-1.136 1.136L10 11.136l-1.832 1.832a.802.802 0 11-1.136-1.136L8.864 10 7.032 8.168a.803.803 0 111.136-1.136L10 8.864l1.832-1.832a.803.803 0 111.136 1.136L11.136 10l1.832 1.832z"
        />
      </g>
    ),
    viewBox: '0 0 20 20',
    height: '20px',
    width: '20px',
  },
];

export const socialLinks = {
  facebook: {
    name: 'Facebook',
    url: 'https://www.facebook.com/EasyProcur',
  },
  instagram: {
    name: 'Instagram',
    url: 'https://www.instagram.com/easyprocur/',
  },
  twitter: {
    name: 'Twitter',
    url: 'https://twitter.com/easyprocur',
  },
  youtube: {
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCZZ2-LxKBmz6KXyfD0OJJCw/',
  },
  linkedin: {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/easyprocur/',
  },
};

export const testimonials = [
  {
    id: 0,
    content:
      'This Platform is the only one of its kinds and had lead to the  growth in my Client base and my business ,  I am glad I got to know about this and have subscribed here  . I will recommend this platform to all the Corporate supplier fraternity for a fruitful business association with Corporate Sourcing teams ',
    person: 'Jay Mistry, Zazzy',
    avatar: '/assets/img/headshot_jay.jpg',
  },
  {
    id: 1,
    content:
      ' No more running around to convince the Clients for business . We can showcase our Profile elaborately and convincingly to the Corporate  Procurement team being in the confines of our office . This feature will help us to achieve significant business growth.',
    person: 'Nishant Gupta, Radiant',
    avatar: '/assets/img/headshot_nishant.jpg',
  },
  // {
  //   id: 2,
  //   content:
  //     ' I am impressed by the unique features of this platform , this platform guarantees amazing business growth , I encourage each one of you to register here and reap rich rewards',
  //   person: 'Kumar Rajguru, CarIQ',
  //   avatar: '/assets/img/headshot_kumar.jpg',
  // },
];
