import React, { Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Row from 'components/Layout/Row';
import Container from 'components/Layout/Container';
import Column from 'components/Layout/Column';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Logo from 'components/Logo';

import { Page } from './login.styled';

const LoginForm = lazy(() => import('modules/Users/container/LoginForm'));

const LoginPage = () => (
  <Page>
    <Helmet title="Login | EasyProcur" />
    <Container width="1280px">
      <Row align="center" justify="space-between">
        <Column className="center">
          <img src="/assets/img/coprocur-signup.jpg" alt="coprocur" />
        </Column>
        <Column>
          <Link to="/" className="logo-link no-underline">
            <Logo isDark />
          </Link>
          <div className="form-box">
            <h2 className="center">Welcome Back</h2>
            <Suspense fallback={<Loading />}>
              <LoginForm />
            </Suspense>
            <Button type="a" to="/sign-up" className="signup-button">
              New Here? Create a New Account
            </Button>
          </div>
        </Column>
      </Row>
    </Container>
  </Page>
);

export default LoginPage;
