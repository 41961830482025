import styled from 'styled-components';

export default styled.div`
  position: relative;
  z-index: 1;

  .select-cities,
  .select-search {
    display: inline-block;
    vertical-align: bottom;

    &__menu {
      width: 100%;
      text-align: left;
    }
  }

  .select-cities {
    min-width: ${({ isHome }) => (isHome ? '170px' : '130px')};

    & > div > div {
      flex-wrap: nowrap;
    }
  }

  .select-search {
    width: ${({ isHome }) => (isHome ? '550px' : '225px')};
    max-width: 60%;

    &__indicator-separator,
    &__dropdown-indicator {
      display: none;
    }

    &__clear-indicator {
      margin-right: ${({ isHome }) => (isHome ? '40px' : '20px')};
    }
  }

  .search-button {
    position: absolute;
    top: 50%;
    right: ${({ isHome }) => (isHome ? '16px' : '12px')};
    transform: translateY(-50%);

    img {
      vertical-align: top;
    }
  }
`;
