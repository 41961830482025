import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';

import { LegalDetailsSection } from './SupplierDetails.styled';

const LegalDetails = ({ supplier }) => (
  <LegalDetailsSection id="more">
    <h4>
      <span>More Details</span>
    </h4>
    <Row justify="flex-start">
      {supplier.Register_Of_Company_city && (
        <Column>
          <p>Register Of Company</p>
          <p>{supplier.Register_Of_Company_city}</p>
        </Column>
      )}
      {supplier.company_type && (
        <Column>
          <p>Company Type</p>
          <p>{supplier.company_type}</p>
        </Column>
      )}
      {supplier.registration_number && (
        <Column>
          <p>Registration Number</p>
          <p>{supplier.registration_number}</p>
        </Column>
      )}
      {supplier.CIN_Company_Identification_Number && (
        <Column>
          <p>CIN Number</p>
          <p>{supplier.CIN_Company_Identification_Number}</p>
        </Column>
      )}
      {supplier.company_turnover && (
        <Column>
          <p>Turnover(in Lakh)</p>
          <p>{supplier.company_turnover}</p>
        </Column>
      )}
      {supplier.company_listing_status && (
        <Column>
          <p>Listing Status</p>
          <p>{supplier.company_listing_status}</p>
        </Column>
      )}
      {supplier.authorised_capital && (
        <Column>
          <p>Authorized Capital(in Lakh)</p>
          <p>{supplier.authorised_capital}</p>
        </Column>
      )}
      {supplier.paid_up_capital && (
        <Column>
          <p>Paid up Capital(in Lakh)</p>
          <p>{supplier.paid_up_capital}</p>
        </Column>
      )}
      {supplier.last_agm_date && (
        <Column>
          <p>Last AGM Date</p>
          <p>{dayjs(supplier.last_agm_date).format('MMM D, YYYY')}</p>
        </Column>
      )}
      {supplier.last_balancesheet_date && (
        <Column>
          <p>Last Balancesheet Date</p>
          <p>{dayjs(supplier.last_balancesheet_date).format('MMM D, YYYY')}</p>
        </Column>
      )}
    </Row>
  </LegalDetailsSection>
);

LegalDetails.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default LegalDetails;
