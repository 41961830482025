import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import {
  getUserDetails,
  userSupplierSuccess,
} from 'modules/Users/store/actions';
import {
  addConnection,
  suppliersSearchSuccess,
} from 'modules/Listing/store/actions';

import history from 'utils/history';

import App from '../components/App';

import {
  hideSnackbar,
  updateCompareSuppliers,
  toggleContactSupplier,
} from '../store/actions';

ReactGA.initialize('UA-180488579-1');

const AppContainer = ({
  dispatch,
  user,
  snackbar,
  compareSuppliers,
  showContactSupplier,
  suppliersSearch,
}) => {
  function closeSnackbar() {
    dispatch(hideSnackbar());
  }

  function clearComparision() {
    dispatch(updateCompareSuppliers([]));
  }

  function removeComparisionSupplier(payload) {
    dispatch(
      updateCompareSuppliers(compareSuppliers.filter((i) => i.id !== payload)),
    );
  }

  function addConnectionDispatch(payload, callback) {
    dispatch(
      addConnection(
        {
          search_id: suppliersSearch.searchId,
          ...(user._id && user._id.length > 0 ? { user_id: user._id } : {}),
          ...payload,
        },
        callback,
      ),
    );
  }

  function closeSupplierModal() {
    dispatch(toggleContactSupplier(false));
    if (
      history.location.pathname === '/dashboard' ||
      history.location.pathname === '/dashboard/' ||
      history.location.pathname.startsWith('/s/')
    )
      dispatch(
        suppliersSearchSuccess({
          searchId: '',
          searchKeyword: '',
          searchCities: [],
          searchResults: [],
        }),
      );
  }

  useEffect(() => {
    if (!(user && user.x_auth_token)) {
      const token = window.localStorage.getItem('user_auth_token');

      if (token) dispatch(getUserDetails(token));
    }
  }, [user, dispatch]);

  useEffect(() => {
    /* 
    if (
      user &&
      user.is_supplier === true &&
      history.location.pathname !== '/profile/supplier' &&
      history.location.pathname !== '/sign-up/supplier/success' &&
      history.location.pathname !== '/profile'
    )
      history.push('/profile/supplier');
    */

    if (!user || Object.keys(user).length === 0)
      dispatch(userSupplierSuccess({}));
  }, [user, dispatch]);

  useEffect(
    () =>
      history.listen((location) => {
        ReactGA.pageview(`${location.pathname}${location.search}`);
        if (
          compareSuppliers.length > 0 &&
          location.pathname.startsWith('/search') === false &&
          location.pathname.startsWith('/dashboard') === false
        )
          dispatch(updateCompareSuppliers([]));
      }),
    [dispatch, compareSuppliers],
  );

  useEffect(() => {
    ReactGA.pageview(`${history.location.pathname}${history.location.search}`);
  }, []);

  return (
    <App
      snackbar={snackbar}
      closeSnackbar={closeSnackbar}
      compareSuppliers={compareSuppliers}
      clearComparision={clearComparision}
      removeComparisionSupplier={removeComparisionSupplier}
      showSupplier={
        showContactSupplier &&
        suppliersSearch.searchResults.filter((i) => showContactSupplier.includes(i._id))
      }
      suppliersSearchID={suppliersSearch.searchId}
      addConnection={addConnectionDispatch}
      closeSupplierModal={closeSupplierModal}
      user={user}
    />
  );
};

const mapStateToProps = ({
  user: { user },
  app: { snackbar, compareSuppliers, showContactSupplier },
  listing: { suppliersSearch },
}) => ({
  user,
  snackbar,
  compareSuppliers,
  showContactSupplier,
  suppliersSearch,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

AppContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.object.isRequired,
  compareSuppliers: PropTypes.array.isRequired,
  showContactSupplier: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  suppliersSearch: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
