import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Container from 'components/Layout/Container';
import Button from 'components/Button';
import Logo from 'components/Logo';

const ErrorPage = ({ match }) => {
  const isUnauthenticated = useRef(
    match.path === '/dashboard' || match.path === '/profile',
  );

  return (
    <main className="center" style={{ padding: '40px 0' }}>
      <Helmet
        title={`${
          isUnauthenticated.current ? 'Login required' : 'Error 404'
        } | EasyProcur`}
      />
      <Container>
        <Logo isDark width="100px" />
        <h1 style={{ marginTop: 80, fontSize: 38 }}>
          {isUnauthenticated.current ? 'Login required' : 'Error 404'}
        </h1>
        <p style={{ margin: '20px 0' }}>
          {`The page you are looking for ${
            isUnauthenticated.current
              ? 'requires authentication'
              : 'does not exist'
          }.`}
        </p>
        <Button type="a" to={isUnauthenticated.current ? '/login' : '/'} dark>
          {isUnauthenticated.current ? 'Login' : 'Go home'}
        </Button>
      </Container>
    </main>
  );
};

ErrorPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ErrorPage;
