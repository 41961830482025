import React from 'react';
import { Helmet } from 'react-helmet';

import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';
import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';
import Button from 'components/Button';

import { Banner, Story, Values, Presence, Partner } from './about.styled';

const AboutPage = () => (
  <DashboardLayout>
    <Helmet title="About EasyProcur" />
    <Banner>
      <Container>
        <h1 className="center">
          Our Mission is to revolutionize a mature and collaborative supply
          chain platform
        </h1>
      </Container>
    </Banner>
    <Story>
      <Container>
        <Row className="story-row">
          <Column flex="3">
            <h2>
              <span>Our Story</span>
            </h2>
            <p>
              The Platform has been conceptualized in consultation with Senior
              Industry Professionals associated with large multinational BFSI,
              IT- ITES, Telecom, media and mfg. conglomerates.
            </p>
            <p>
              This platform onboards verified corporate suppliers with their
              credentials to help reach out to Corporate buyers.
            </p>
          </Column>
          <Column flex="2" className="right">
            <img src="/assets/img/contact-us.jpg" alt="team" />
          </Column>
        </Row>
      </Container>
    </Story>
    <Values>
      <Container>
        <h2>
          <span>Our Offering </span>
        </h2>
        <Row className="values">
          <Column>
            <img src="/assets/img/certified.svg" alt="certified" />
            <span>Simplified location specific search process</span>
          </Column>
          <Column>
            <img src="/assets/img/india.svg" alt="india" />
            <span>PAN-India presence</span>
          </Column>
          <Column>
            <img src="/assets/img/hassle-free.svg" alt="hassle free" />
            <span>Exclusive Corporate Supplier database</span>
          </Column>
          <Column>
            <img src="/assets/img/team.svg" alt="team" />
            <span>Exhaustive database of Real Time Verified Suppliers</span>
          </Column>
        </Row>
      </Container>
    </Values>
    <Presence>
      <Container>
        <Row className="presence-row">
          <Column className="center">
            <img
              src="/assets/img/india-map.jpg"
              alt="india map"
              width="519px"
            />
          </Column>
          <Column>
            <h2>PAN-India Presence</h2>
            <div className="numbers">
              <span>100+</span>
              <br />
              <span>Active Services</span>
            </div>
            <div className="numbers">
              <span>5000+</span>
              <br />
              <span>Partners Across India</span>
            </div>
            <div className="numbers" style={{ margin: 0 }}>
              <span>76+</span>
              <br />
              <span>Platform transactions and growing</span>
            </div>
          </Column>
        </Row>
      </Container>
    </Presence>
    <Partner>
      <Container>
        <div className="card">
          <Row>
            <Column>
              <h3>Partner with EasyProcur</h3>
              <p>
                Suppliers can register to enhance their business opportunities. 
                Corporate procurement and REFM teams to search for the most
                preferred Corporate suppliers.
              </p>
            </Column>
            <Column className="right">
              <Button type="a" to="/sign-up" styled dark>
                Join Our Network
              </Button>
            </Column>
          </Row>
        </div>
      </Container>
    </Partner>
  </DashboardLayout>
);

export default AboutPage;
