import React from 'react';
import PropTypes from 'prop-types';

import Container from 'components/Layout/Container';
import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';
import Button from 'components/Button';
import Icon from 'components/Icon';

import getImage from 'utils/getImage';

import Bar from './ComparisionBar.styled';

const ComparisionBar = ({
  compareSuppliers,
  clearComparision,
  removeComparisionSupplier,
}) => {
  function compareClickHandler() {
    window.open(
      `${window.location.origin}/compare/${compareSuppliers
        .map((i) => i.id)
        .join('/')}`,
      '_blank',
    );
  }

  function removeClickHandler(e) {
    removeComparisionSupplier(e.target.dataset.id);
  }

  return (
    <Bar>
      <Container>
        <Row align="center">
          <Column>
            <span>Compare upto three suppliers</span>
          </Column>
          <Column>
            <ul>
              {compareSuppliers.map((i) => (
                <li key={i.id}>
                  <img src={getImage(i.logo)} alt="supplier" />
                  <Button
                    type="button"
                    data-id={i.id}
                    onClick={removeClickHandler}>
                    <Icon name="close" />
                  </Button>
                </li>
              ))}
              {[...Array(3 - compareSuppliers.length).keys()].map((i) => (
                <li key={i} className="empty" />
              ))}
            </ul>
          </Column>
          <Column className="right">
            <Button type="button" onClick={clearComparision}>
              Cancel
            </Button>
            <Button
              type="button"
              styled
              dark
              disabled={compareSuppliers.length < 2}
              onClick={compareClickHandler}
              style={{ marginLeft: 35 }}>
              Compare
            </Button>
          </Column>
        </Row>
      </Container>
    </Bar>
  );
};

ComparisionBar.propTypes = {
  compareSuppliers: PropTypes.array.isRequired,
  clearComparision: PropTypes.func.isRequired,
  removeComparisionSupplier: PropTypes.func.isRequired,
};

export default ComparisionBar;
