import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ isDark, width }) => (
  <img
    // src={`/assets/img/logo-${isDark ? 'dark' : 'white'}.png`}
    src={`/assets/img/easyprocur-logo/png/${isDark ? 'logo-black' : 'logo-no-background'}.png`}
    alt="EasyProcur logo"
    width={width || '150px'}
  />
);

Logo.propTypes = {
  isDark: PropTypes.bool,
  width: PropTypes.string,
};

export default Logo;
