import React from 'react';
import PropTypes from 'prop-types';

import LightboxCarousel from 'components/LightboxCarousel';

/* eslint-disable indent */

const Gallery = ({ supplier }) =>
  (supplier.promotional_video && supplier.promotional_video.length > 0) ||
  (supplier.earlier_project && supplier.earlier_project.length > 0) ? (
    <div id="gallery">
      <h4>
        <span>Gallery</span>
      </h4>
      <LightboxCarousel
        videos={supplier.promotional_video}
        images={supplier.earlier_project}
      />
    </div>
  ) : (
    <></>
  );

/* eslint-enable indent */

Gallery.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default Gallery;
