import styled from 'styled-components';

export default styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  padding: 60px 0;

  .content {
    background-color: #ffffff;
    padding: 30px 16px 5px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    max-width: 800px;
    margin: auto;

    h1 {
      font-size: 24px;
      margin-bottom: 25px;
    }

    h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }

    p,
    li {
      font-size: 18px;
      opacity: 0.8;
      margin-bottom: 24px;
    }
  }
`;
