import styled from 'styled-components';

export default styled.div`
  position: relative;

  .items {
    display: flex;
    margin-top: 45px;
    margin-right: -115px;
    overflow: hidden;

    & > div {
      padding: 15px;
      border-radius: 4px;
      border: 1px solid ${({ theme: { colors } }) => colors.deepLightGrey};
      min-width: 230px;
      margin-right: 15px;
      transition: ${({ theme: { transition } }) => transition};
    }
  }

  .toggle-box {
    position: absolute;
    top: -30px;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 5px;
      padding: 0;

      .chevron-button {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.5 !important;
          cursor: not-allowed;
        }

        svg {
          fill: ${({ theme: { colors } }) => colors.blue};
          pointer-events: none;
        }
      }
    }
  }
`;
