import React from 'react';
import PropTypes from 'prop-types';

import Pages from 'pages';

import Snackbar from 'components/Snackbar';

import ComparisionBar from './ComparisionBar';
import ContactSupplier from './ContactSupplier';

const App = ({
  snackbar,
  closeSnackbar,
  compareSuppliers,
  clearComparision,
  removeComparisionSupplier,
  showSupplier,
  addConnection,
  closeSupplierModal,
  user,
}) => (
  <>
    <Pages />
    <Snackbar
      show={snackbar.showSnackbar}
      message={snackbar.message}
      closeSnackbar={closeSnackbar}
    />
    {compareSuppliers.length > 0 && (
      <ComparisionBar
        compareSuppliers={compareSuppliers}
        clearComparision={clearComparision}
        removeComparisionSupplier={removeComparisionSupplier}
      />
    )}
    {showSupplier && (
      <ContactSupplier
        supplierList={showSupplier}
        addConnection={addConnection}
        closeSupplierModal={closeSupplierModal}
        user={user}
      />
    )}
  </>
);

App.propTypes = {
  snackbar: PropTypes.object.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  compareSuppliers: PropTypes.array.isRequired,
  clearComparision: PropTypes.func.isRequired,
  removeComparisionSupplier: PropTypes.func.isRequired,
  showSupplier: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  addConnection: PropTypes.func.isRequired,
  closeSupplierModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default App;
