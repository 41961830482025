export default {
  colors: {
    black: '#010709',
    grey: '#545556',
    lightGrey: '#f7f8f8',
    deepLightGrey: '#d9dadb',
    lightBlue: '#edecff',
    deepLightBlue: '#80c5ff',
    blue: '#008cff',
    blueHover: '#015ba5',
    darkBlue: '#615ba6',
    red: 'red',
    dullYellow: '#fdfaeb',
    gold: '#ae9605',
    greenBlue: '#0e8d43',
  },
  transition: 'all 0.25s',
  transitionIn: 'all 0.15s cubic-bezier(0.0, 0.0, 0.2, 1)',
  transitionOut: 'all 0.25s cubic-bezier(0.4, 0.0, 1, 1)',
  shadow: '0 1px 12px 0 rgba(0, 0, 0, 0.08)',
};
