import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';

import { Container } from './Snackbar.styled';

const Snackbar = ({ show, message, closeSnackbar }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (String(message).length > 1) setText(message);
  }, [message]);

  return (
    <Container className={show && message ? 'show' : ''}>
      <span>{text}</span>
      <Button type="button" onClick={closeSnackbar}>
        <Icon
          name="close"
          fill="#ffffff"
          width="22px"
          height="22px"
          verticalAlign="middle"
        />
      </Button>
    </Container>
  );
};

Snackbar.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeSnackbar: PropTypes.func,
};

export default Snackbar;
