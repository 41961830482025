import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { store } from 'store/createStore';
import App from 'modules/App';

import GobalStyles from 'styles/globalStyles';
import theme from 'styles/theme';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GobalStyles />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
