import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Row from 'components/Layout/Row';
import Container from 'components/Layout/Container';
import Button from 'components/Button';
import Logo from 'components/Logo';
import SearchForm from 'components/SearchForm';
import Icon from 'components/Icon';

import { socialLinks } from 'utils/contentProvider';

import Profile from './Profile';

import { HeaderContainer, MobileMenu } from './Header.styled';

const Header = ({
  cities,
  tags,
  searchHandler,
  user,
  logOut,
  pathname,
  isDark,
  searchDefaults,
}) => {
  const [placeholderHeight, setPlaceholderHeight] = useState(51);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const header = useRef();

  function toggleMobileMenu() {
    setMobileMenu(!mobileMenu);
  }

  function menuClickHandler(e) {
    if (e.target.id === 'mobile-menu') setMobileMenu(false);
  }

  function closeMenu() {
    setMobileMenu(false);
  }

  function toggleMobileSearch() {
    setMobileSearch(!mobileSearch);
  }

  useEffect(() => {
    if (header && header.current)
      setTimeout(() => {
        setPlaceholderHeight(header.current && header.current.clientHeight-5); 
      }, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('click', menuClickHandler);

    return () => window.removeEventListener('click', menuClickHandler);
  });

  useEffect(() => {
    document.body.style.overflow = mobileMenu ? 'hidden' : 'auto';
  }, [mobileMenu]);

  return (
    <>
      <HeaderContainer id="nav" ref={header} isDark={isDark}>
        <Container>
          <Row justify="space-between" className="nav-row">
            <div>
              <Link to="/" className="no-underline">
                <Logo isDark={!isDark} width="123px" />
              </Link>
            </div>
            {/* eslint-disable indent */}
            {pathname !== '/' && !pathname.startsWith('/profile') && (
              <>
                <div
                  className={`center search-form-column${
                    mobileSearch ? ' mobile-active' : ''
                  }`}>
                  <SearchForm
                    cities={cities}
                    tags={tags}
                    searchHandler={searchHandler}
                    defaults={searchDefaults}
                  />
                </div>
                <Button
                  type="button"
                  className="toggle-mobile-search"
                  onClick={toggleMobileSearch}>
                  <Icon
                    name="search"
                    width="16px"
                    verticalAlign="middle"
                    fill={isDark ? '#ffffff' : '#000000'}
                  />
                </Button>
              </>
            )}
            <div
              className={`menu-column${
                user.is_supplier === true ? ' is-supplier' : ''
              }`}>
              {(Object.keys(user).length === 0 ? (
                true
              ) : (
                user.is_supplier === false
              )) ? (
                <>
                  <div className="menu-column__large">
                    <Profile logOut={logOut} user={user} isDark={isDark} />
                  </div>
                  <div className="menu-column__small">
                    <Button
                      type="button"
                      className="mobile-menu-toggle"
                      onClick={toggleMobileMenu}>
                      <Icon name="menu" verticalAlign="middle" />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {pathname.startsWith('/profile/supplier') ? (
                    <Link to="/profile" className="supplier-menu-link">
                      User settings
                    </Link>
                  ) : (
                    <Link to="/profile/supplier" className="supplier-menu-link">
                      Supplier settings
                    </Link>
                  )}
                  <Button
                    type="button"
                    onClick={logOut}
                    className="supplier-menu-link">
                    Log out
                  </Button>
                </>
              )}
              {/* eslint-enable indent */}
            </div>
          </Row>
        </Container>
      </HeaderContainer>
      <MobileMenu id="mobile-menu" isActive={mobileMenu}>
        <div className="container">
          <ul className="social-menu">
            <li>
              <a
                href={socialLinks.facebook.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon
                  name="facebook"
                  width="18px"
                  height="18px"
                  verticalAlign="middle"
                />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.twitter.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon
                  name="twitter"
                  width="18px"
                  height="18px"
                  verticalAlign="middle"
                />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.instagram.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon
                  name="instagram"
                  width="18px"
                  height="18px"
                  verticalAlign="middle"
                />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.youtube.url}
                className="no-underline"
                target="_blank"
                rel="noopener noreferrer">
                <Icon
                  name="youtube"
                  height="18px"
                  width="18px"
                  verticalAlign="middle"
                />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.linkedin.url}
                className="no-underline"
                target="_blank"
                rel="noopener noreferrer">
                <Icon
                  name="linkedin"
                  height="18px"
                  width="18px"
                  verticalAlign="middle"
                />
              </a>
            </li>
          </ul>
          <ul className="main-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            {user && user.x_auth_token && (
              <>
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
              </>
            )}
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Use</Link>
            </li>
          </ul>
          <Profile user={user} logOut={logOut} isMobile />
        </div>
        <Button type="button" onClick={closeMenu} className="menu-close">
          <Icon name="close" verticalAlign="middle" />
        </Button>
      </MobileMenu>
      <div style={{ height: placeholderHeight }} />
    </>
  );
};

Header.propTypes = {
  cities: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  searchHandler: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  isDark: PropTypes.bool,
  searchDefaults: PropTypes.object.isRequired,
};

export default Header;
