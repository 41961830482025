import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEmail from 'validator/lib/isEmail';

import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';
import Button from 'components/Button';
import Icon from 'components/Icon';

import getImage from 'utils/getImage';
import { socialLinks } from 'utils/contentProvider';

import ContactModal from './ContactSupplier.styled';
import supplier from 'pages/supplier';

const Contact = ({ supplierList, addConnection, closeSupplierModal, user }) => {
  const [connectionSending, setConnectionSending] = useState(false);
  const [connectedSuccess, setConnectedSuccess] = useState(false);
  const [contactInput, setContactInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });
  const [contactErrors, setContactErrors] = useState([]);

  function closeModal() {
    closeSupplierModal();
    setConnectionSending(false);
    setConnectedSuccess(false);
    setContactInput({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      message: '',
    });
  }

  function contactInputChangeHandler(e) {
    setContactInput({
      ...contactInput,
      [e.target.name]:
        e.target.name === 'phone' && String(Number(e.target.value)) === 'NaN'
          ? contactInput[e.target.name]
          : e.target.value,
    });
  }

  function contactSubmitHandler(e) {
    const newErrors = [];

    e.preventDefault();

    setContactErrors([]);

    if (!contactInput.firstName || contactInput.firstName.length < 2)
      newErrors.push({
        name: 'firstName',
        message: 'Please enter first name of 2 characters or more.',
      });

    if (
      !contactInput.email ||
      contactInput.email.length < 6 ||
      !isEmail(contactInput.email)
    )
      newErrors.push({
        name: 'email',
        message: 'Please enter a valid email address.',
      });

    if (
      contactInput.phone &&
      contactInput.phone.length > 0 &&
      contactInput.phone.length < 10 &&
      contactInput.phone.length !== 10
    )
      newErrors.push({
        name: 'phone',
        error: 'Please enter phone number of 10 numbers.',
      });

    if (
      contactInput.company &&
      contactInput.company.length > 0 &&
      contactInput.company.length < 5
    )
      newErrors.push({
        name: 'company',
        error: 'Please enter company name of 5 characters or more.',
      });

    if (!contactInput.message || contactInput.message.length < 1)
      newErrors.push({ name: 'message', message: 'Please enter a message.' });

    if (newErrors.length > 0) {
      setContactErrors(newErrors);
      return;
    }

    if (newErrors.length === 0) {
      supplierList.map((supplier) => {
        addConnection(
          {
            supplier_id: supplier._id,
            message: contactInput.message,
            user_first_name: contactInput.firstName,
            user_last_name: contactInput.lastName,
            user_email: contactInput.email,
            ...(contactInput.phone != null
              ? { user_phone: contactInput.phone }
              : {}),
            ...(contactInput.company !=null
              ? { user_company: contactInput.company }
              : {}),
          },
          addConnectionCallback,
        );
      });
      setConnectionSending(true);
    }
  }

  function addConnectionCallback({ type, data }) {
    if (type === 'error') {
      setContactErrors([{ name: 'submit', message: data }]);
      setConnectionSending(false);
      setConnectedSuccess(false);
    }
    if (type === 'success') {
      setConnectionSending(true);
      setConnectedSuccess(true);
    }
  }

  useEffect(() => {
    if (user && user._id)
      setContactInput({
        firstName: user.user_first_name,
        lastName: user.user_last_name,
        email: user.user_email,
        phone: user.user_phone,
        company: user.user_company,
        message: '',
      });
  }, [user]);

  return (
    <ContactModal>
      <div className="content">
        <Row justify="space-between" style={{ margin: 0 }}>
          <h4>Connect To Supplier</h4>
          <Button type="button" className="close-button" onClick={closeModal}>
            <Icon
              name="close"
              verticalAlign="bottom"
              height="20px"
              width="20px"
            />
          </Button>
        </Row>
        {supplierList.map((supplier)=> 
        (<Row className="supplier-row" justify="flex-start">
        <div>
          <img
            src={getImage(supplier.company_logo)}
            alt={supplier.supplier_name}
          />
        </div>
        <div>
          <h2>{supplier.supplier_name}</h2>
          {supplier.company_formation_date && (
            <p>
              {`Since ${dayjs(supplier.company_formation_date).format(
                'MMM D, YYYY',
              )}`}
            </p>
          )}
        </div>
      </Row>

        )
        )}
        
        {connectedSuccess ? (
          <>
            <Row className="success-message">
              <div>
                <Icon
                  name="tick-done"
                  height="64px"
                  width="64px"
                  verticalAlign="middle"
                />
              </div>
              <div>
                <p>
                  Thanks for reaching out. Our team will get back to you ASAP.
                  Meanwhile, you can follow us here.
                </p>
              </div>
            </Row>
            <ul className="social-links">
              <li>
                <a
                  href={socialLinks.facebook.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="facebook" height="16px" width="16px" />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.twitter.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="twitter" height="16px" width="16px" />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.instagram.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="instagram" height="16px" width="16px" />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.youtube.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="youtube" height="16px" width="16px" />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.linkedin.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="linkedin" height="16px" width="16px" />
                </a>
              </li>
            </ul>
          </>
        ) : (
          <form onSubmit={contactSubmitHandler}>
            <Row>
              <Column>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  className={
                    contactErrors.some((i) => i.name === 'firstName')
                      ? 'has-error'
                      : ''
                  }
                  value={contactInput.firstName}
                  onChange={contactInputChangeHandler}
                />
              </Column>
              <Column>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  className={
                    contactErrors.some((i) => i.name === 'lastName')
                      ? 'has-error'
                      : ''
                  }
                  value={contactInput.lastName}
                  onChange={contactInputChangeHandler}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  className={
                    contactErrors.some((i) => i.name === 'email')
                      ? 'has-error'
                      : ''
                  }
                  value={contactInput.email}
                  onChange={contactInputChangeHandler}
                />
              </Column>
              <Column className="phone-container">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Mobile Number (optional)"
                  className={
                    contactErrors.some((i) => i.name === 'phone')
                      ? 'has-error'
                      : ''
                  }
                  value={contactInput.phone}
                  onChange={contactInputChangeHandler}
                />
              </Column>
            </Row>
            <input
              type="text"
              name="company"
              id="company"
              placeholder="Company Name (optional)"
              className={
                contactErrors.some((i) => i.name === 'company')
                  ? 'has-error'
                  : ''
              }
              value={contactInput.company}
              onChange={contactInputChangeHandler}
            />
            <textarea
              name="message"
              id="message"
              placeholder="Enter a message"
              className={
                contactErrors.some((i) => i.name === 'message')
                  ? 'has-error'
                  : ''
              }
              value={contactInput.message}
              onChange={contactInputChangeHandler}
            />
            {contactErrors.length > 0 && (
              <ul className="errors">
                {contactErrors.map((i) => (
                  <li key={i.name}>{i.message}</li>
                ))}
              </ul>
            )}
            <div className="right">
              <Button type="submit" dark disabled={connectionSending}>
                {connectionSending ? 'Sending' : 'Send'}
              </Button>
            </div>
          </form>
        )}
      </div>
    </ContactModal>
  );
};

Contact.propTypes = {
  supplierList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  closeSupplierModal: PropTypes.func.isRequired,
  addConnection: PropTypes.func.isRequired,
};

export default Contact;
