import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Row from 'components/Layout/Row';

import UserDropdown from './UserDropdown';

import { UserInitials } from './Header.styled';

const Profile = ({ user, logOut, isMobile, isDark }) => (
  <>
    {user && user.x_auth_token ? (
      <>
        {isMobile ? (
          <Row
            className="profile-mobile"
            justify="flex-start"
            style={{ margin: 0 }}>
            <UserInitials isDark={isDark}>
              {`${user.user_first_name.charAt(0)} ${user.user_last_name.charAt(
                0,
              )}`}
            </UserInitials>
            <div className="profile-mobile-details">
              <span className="name">{`${user.user_first_name} ${user.user_last_name}`}</span>
              <br />
              <Button type="button" onClick={logOut}>
                Log out
              </Button>
            </div>
          </Row>
        ) : (
          <UserDropdown user={user} logOut={logOut} isDark={isDark} />
        )}
      </>
    ) : (
      <>
        {isMobile ? (
          <div className="menu-profile">
            <Button type="a" to="/login">
              Login
            </Button>
            <Button
              type="a"
              to="/sign-up"
              dark="true"
              style={{ marginLeft: 12 }}>
              Signup
            </Button>
          </div>
        ) : (
          <>
            <Link
              to="/login"
              className="no-underline"
              style={{ padding: '5px 15px' }}>
              Login
            </Link>
            <Button type="a" to="/sign-up" style={{ marginLeft: 9 }}>
              Signup
            </Button>
          </>
        )}
      </>
    )}
  </>
);

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isDark: PropTypes.bool,
};

export default Profile;
