import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';

import { User, UserInitials, UserMenu } from './Header.styled';

const UserDropdown = ({ user, logOut, isDark }) => {
  const [isActive, setActive] = useState(false);

  function menuClickHandler() {
    setActive(!isActive);
  }

  return (
    <>
      <User isDark={isDark}>
        <Button type="button" onClick={menuClickHandler}>
          <UserInitials isDark={isDark}>
            {`${user.user_first_name.charAt(0)} ${user.user_last_name.charAt(
              0,
            )}`}
          </UserInitials>
          <span className="name">{`${user.user_first_name} ${user.user_last_name}`}</span>
          <Icon
            name="chevron"
            rotate={isActive ? '90deg' : '270deg'}
            verticalAlign="middle"
            width="11px"
            heigh="11px"
          />
        </Button>
        <UserMenu isDark={isDark} className={isActive ? 'active' : ''}>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/profile">Profile</Link>
          </li>
          <li>
            <Button type="button" onClick={logOut}>
              Log out
            </Button>
          </li>
        </UserMenu>
      </User>
      {isActive && (
        <Button
          type="button"
          className="close-menu-bg-button"
          onClick={menuClickHandler}
        />
      )}
    </>
  );
};

UserDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};

export default UserDropdown;
