import { all, fork } from 'redux-saga/effects';
import * as userSagas from 'modules/Users/store/sagas';
import * as listingSagas from 'modules/Listing/store/sagas';
import * as dashboardSagas from 'modules/Dashboard/store/sagas';

export default function* rootSaga() {
  yield all([...Object.values(userSagas)].map(fork));
  yield all([...Object.values(listingSagas)].map(fork));
  yield all([...Object.values(dashboardSagas)].map(fork));
}
