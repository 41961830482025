import styled from 'styled-components';

export default styled.div`
  position: fixed;
  z-index: 11;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  background-color: #0c2436;

  &,
  button {
    color: #ffffff;
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      background-color: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      width: 100px;
      height: 50px;

      img {
        max-height: 100%;
      }

      button {
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: ${({ theme: { colors } }) => colors.red};
        border-radius: 20px;
        padding: 3px;
        height: 20px;
        width: 20px;

        svg {
          pointer-events: none;
          height: 14px;
          width: 14px;
          fill: #ffffff;

          path {
            stroke: #ffffff;
          }
        }
      }

      &.empty {
        border: 2px dashed #ffffff;
        background: none;
        opacity: 0.3;
      }
    }
  }
`;
