import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import axios from 'axios';

import api from 'utils/api';
import history from 'utils/history';

import { updateCompareSuppliers } from 'modules/App/store/actions';
import { currentUser } from 'modules/Users/store/selectors';

import {
  actions,
  suppliersSearchSuccess,
  toggleSearchLoading,
  citiesSuccess,
  tagsSuccess,
  psTagsSuccess,
  psTagsRequest,
  comparisionSupplierSuccess,
  partnersSuccess,
} from './actions';

function* suppliersSearchRequestSaga({ payload }) {
  const user = yield select(currentUser);
  if (user && user._id) payload.user_id = user._id;

  yield put(toggleSearchLoading(true));
  yield put(updateCompareSuppliers([]));

  try {
    const searchResponse = yield call(axios.post, api.searchSupplier, payload);

    if (searchResponse.data) {
      if (history.location.pathname !== '/search') history.push('/search');
      yield put(
        suppliersSearchSuccess({
          searchId: searchResponse.data.SearchReport[0].search_id,
          searchKeyword: payload.search_text,
          searchCities: [...payload.cities],
          searchResults:
            searchResponse.data.SearchReport[0].supplier.length > 0
              ? searchResponse.data.SearchReport[0].supplier
              : ['notfound'],
        }),
      );
    }
    yield put(toggleSearchLoading(false));
  } catch (error) {
    if (error) {
      yield put({
        searchKeyword: payload.search_text,
        searchResults: ['notfound'],
      });
      yield put(toggleSearchLoading(false));
    }
  }
}

export function* watchSuppliersSearchRequest() {
  yield takeLatest(
    actions.SUPPLIERS_SEARCH_REQUEST,
    suppliersSearchRequestSaga,
  );
}

function* citiesRequestSaga() {
  try {
    console.log("CITY URL ", api.getCities);
    const citiesResponse = yield call(axios.get, api.getCities);

    if (citiesResponse.data.length > 0) {
      citiesResponse.data = citiesResponse.data.sort().map((i) => ({
        value: i,
        label: i,
      }));
    }

    yield put(citiesSuccess(citiesResponse.data));
  } catch (errors) {
    // Handle errors.
    console.log("Here are errors" + JSON.stringify(errors));
  }
}

export function* watchCitiesRequest() {
  yield takeLatest(actions.CITIES_REQUEST, citiesRequestSaga);
}

function* tagsRequestSaga() {
  try {
    const tagsResponse = yield call(axios.get, api.getTags);

    if (tagsResponse.data.length > 0) {
      tagsResponse.data = tagsResponse.data.sort().map((i) => ({
        value: i,
        label: i,
      }));
    }

    yield put(tagsSuccess(tagsResponse.data));
    yield put(psTagsRequest());
  } catch (errors) {
    // Handle errors.
  }
}

export function* watchTagsRequest() {
  yield takeLatest(actions.TAGS_REQUEST, tagsRequestSaga);
}

function* psTagsRequestSaga() {
  try {
    const tagsResponse = yield call(axios.get, api.getPSTags);

    if (tagsResponse.data.length > 0)
      yield put(psTagsSuccess(tagsResponse.data));
  } catch (errors) {
    // Handle errors.
  }
}

export function* watchPSTagsRequest() {
  yield takeLatest(actions.PS_TAGS_REQUEST, psTagsRequestSaga);
}

function* partnersRequestSaga() {
  try {
    const partnersResponse = yield call(axios.get, api.getPartners);

    if (partnersResponse.data.length > 0)
      yield put(partnersSuccess(partnersResponse.data));
  } catch (errors) {
    // Handle errors.
  }
}

export function* watchPartnersRequest() {
  yield takeLatest(actions.PS_TAGS_REQUEST, partnersRequestSaga);
}

function* comparisionSupplierRequestSaga({ payload }) {
  try {
    const suppliers = yield all(
      payload.map((s) =>
        call(axios, {
          method: 'get',
          url: `${api.getSupplier}/${s}`,
        }),
      ),
    );

    yield put(comparisionSupplierSuccess(suppliers.map((i) => i.data)));
  } catch (error) {
    // Handle errors.
  }
}

export function* watchComparisionSupplierRequest() {
  yield takeLatest(
    actions.COMPARISION_SUPPLIER_REQUEST,
    comparisionSupplierRequestSaga,
  );
}

function* addConnectionSaga({ payload, callback }) {
  try {
    yield call(axios, {
      method: 'post',
      url: api.connectedSuppliers,
      data: payload,
    });
    callback({ type: 'success', data: 'success' });
  } catch (error) {
    // eslint-disable-next-line camelcase
    if (error?.response?.data?.error_msg)
      callback({ type: 'error', data: error.response.data.error_msg });
  }
}

function* addConnectionListSaga({ payload, callback }) {
  try {
    yield call(axios, {
      method: 'post',
      url: api.connectedSuppliers,
      data: payload,
    });
    callback({ type: 'success', data: 'success' });
  } catch (error) {
    // eslint-disable-next-line camelcase
    if (error?.response?.data?.error_msg)
      callback({ type: 'error', data: error.response.data.error_msg });
  }
}

export function* watchAddConnection() {
  yield takeLatest(actions.ADD_CONNECTION, addConnectionSaga);
}
