import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Button from 'components/Button';

const SelectContainer = ({ children, selectProps, ...props }) => (
  <>
    <components.SelectContainer {...props}>
      {children}
      <Button
        type="button"
        className="search-button"
        title="Search"
        onClick={selectProps.searchClickHandler}>
        <img
          src="/assets/img/search.svg"
          alt="search"
          width={selectProps.isHome ? '24px' : '14px'}
          height={selectProps.isHome ? '24px' : '14px'}
        />
      </Button>
    </components.SelectContainer>
  </>
);

SelectContainer.propTypes = {
  children: PropTypes.array.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default SelectContainer;
