import React from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/Carousel';

import { AboutSection } from './SupplierDetails.styled';

/* eslint-disable indent */

const About = ({ supplier }) =>
  supplier.about_company_description ||
  (supplier.our_strengths && supplier.our_strengths.length > 0) ? (
    <AboutSection id="about">
      <h4>
        <span>About Us</span>
      </h4>
      {supplier.about_company_description && (
        <p>{supplier.about_company_description}</p>
      )}
      {supplier.our_strengths && supplier.our_strengths.length > 0 && (
        <Carousel
          items={supplier.our_strengths.map((i) => (
            <div key={i._id} className="about-carousel-item">
              <h3>{i.heading}</h3>
              <p>{i.excerpt}</p>
            </div>
          ))}
        />
      )}
    </AboutSection>
  ) : (
    <></>
  );

/* eslint-enable indent */

About.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default About;
