import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';

const Dashboard = lazy(() => import('modules/Dashboard/container/Dashboard'));

const loadingStyles = {
  height: 'calc(100vh - 250px)',
  textAlign: 'center',
  padding: '200px 0',
};

const DashboardPage = () => (
  <DashboardLayout>
    <Helmet title="Dashboard | EasyProcur" />
    <Suspense fallback={<Loading style={loadingStyles} />}>
      <Dashboard />
    </Suspense>
  </DashboardLayout>
);

export default DashboardPage;
