import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'components/Layout/Container';
import Row from 'components/Layout/Row';
import Button from 'components/Button';

import Page from './signUpSupplierSuccess.styled';

const AccountConfirmPage = () => (
  <Page>
    <Helmet title="Sign Up Success | EasyProcur" />
    <Container>
      <Row
        justify="center"
        align="center"
        direction="column"
        className="center"
        style={{ maxWidth: 1080, margin: 'auto' }}>
        <img src="/assets/img/box.jpg" alt="coprocur box" />
        <h2>Thank you for registering with EasyProcur.</h2>
        <p>
          Our team will get back to you with confirmation. Meanwhile please attempt to fill up all the fields in the next form and click on <strong>"Update Details"</strong>. A comprehensive information will help us to build a strong profile in EasyProcur platform which helps in maximum visibility.
        </p>
        <p>
          Please note that, all fields in next form are optional and you can press <strong>"Update Details"</strong> button if you want to fill it later.        </p>
          <div style={{flexDirection: 'row'}}>
          <Button type="a" dark to="/profile/supplier" style={{margin:20}}>
          Complete your profile
        </Button>
        <Button type="a" dark to="/" style={{margin:20}}>
          Skip for now
        </Button>
          </div>
        
      </Row>
    </Container>
  </Page>
);

export default AccountConfirmPage;
