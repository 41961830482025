import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';

const Compare = lazy(() => import('modules/Listing/container/Compare'));

const ComparePage = ({ match: { params } }) => (
  <DashboardLayout>
    <Helmet title="Compare | EasyProcur" />
    <Suspense fallback={<Loading />}>
      <Compare params={params} />
    </Suspense>
  </DashboardLayout>
);

ComparePage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ComparePage;
