import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import api from 'utils/api';

import ForgotPasswordForm from '../components/ForgotPasswordForm';

import { forgotPassword } from '../store/actions';

const ForgotPasswordFormContainer = ({
  dispatch,
  emailSent,
  forgotPasswordError,
}) => {
  const [input, setInput] = useState('');
  const [errors, setErrors] = useState([]);
  const [step, setStep] = useState(0);
  const history = useHistory();

  const getError = useCallback(
    (field) => errors.find((i) => i.name === field),
    [errors],
  );

  function inputChangeHandler(e) {
    setInput(e.target.value);
  }

  function formSubmitHandler(e) {
    e.preventDefault();

    setErrors([]);

    switch (step) {
      case 0:
        if (validateFields(0))
          axios.get(`${api.checkUser}/${input}`).then(({ data }) => {
            if (data.message === 'User exists') dispatch(forgotPassword(input));
            else
              setErrors([
                {
                  name: 'email',
                  message:
                    'This email address is not registered with the platform.',
                },
              ]);
          });
        break;
      case 1:
        history.push('/login');
        break;
      default:
        break;
    }
  }

  function validateFields(stepNumber) {
    setErrors([]);
    if (stepNumber === 0) {
      if (!input || input.length < 1) {
        setErrors((state) => [
          ...state,
          { name: 'email', message: 'Please enter email address.' },
        ]);
        return false;
      }
      if (!isEmail(input)) {
        setErrors((state) => [
          ...state,
          { name: 'email', message: 'Please enter a valid email address.' },
        ]);
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (step === 0) {
      if (emailSent) setStep(1);
      if (forgotPasswordError) {
        setErrors((state) => [
          ...state,
          { name: 'otp', message: forgotPasswordError },
        ]);
      }
    }
  }, [emailSent, forgotPasswordError, step, dispatch, input]);

  return (
    <ForgotPasswordForm
      input={input}
      inputChangeHandler={inputChangeHandler}
      formSubmitHandler={formSubmitHandler}
      step={step}
      getError={getError}
    />
  );
};

ForgotPasswordFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  user: {
    forgotPassword: { emailSent, forgotPasswordError },
  },
}) => ({
  emailSent,
  forgotPasswordError,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

ForgotPasswordFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  emailSent: PropTypes.bool.isRequired,
  forgotPasswordError: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordFormContainer);
