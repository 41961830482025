import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { DetailsContainer } from './SupplierDetails.styled';
import DetailsAnchorMenu from './DetailsAnchorMenu';
import Brief from './Brief';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import Gallery from './Gallery';
import Testimonials from './Testimonials';
import LegalDetails from './LegalDetails';
import Directors from './Directors';

const should_display_more_details = process.env.REAT_APP_SHOULD_DISPLAY_MORE_DETAILS==='true' ? true : false;


const SupplierDetails = ({
  supplier,
  psTags,
  addToComparision,
  clearSupplier,
  isDashboard,
  isSupplierPage,
  contactSupplier,
}) => {
  const containerRef = useRef();

  function contactHandler(e) {
    e.preventDefault();
    contactSupplier(supplier._id);
  }

  return (
    <DetailsContainer
      id="supplier-details-container"
      ref={containerRef}
      isSupplierPage={isSupplierPage}>
      {(isDashboard || isSupplierPage) && (
        <DetailsAnchorMenu
          clearSupplier={clearSupplier}
          isDashboard={isDashboard}
          isSupplierPage={isSupplierPage}
          isListingPage={false}
          containerRef={containerRef}
        />
      )}
      <div className="anchor-placeholder" />
      <Brief
        supplier={supplier}
        addToComparision={addToComparision}
        contactHandler={contactHandler}
        isSupplierPage={isSupplierPage}
      />
      <About supplier={supplier} />
      <Contact supplier={supplier} contactHandler={contactHandler} />
      <Services supplier={supplier} psTags={psTags} />
      <Gallery supplier={supplier} />
      <Testimonials supplier={supplier} />
      <LegalDetails supplier={should_display_more_details ? supplier : ""} />
      <Directors supplier={should_display_more_details ? supplier : ""} />
    </DetailsContainer>
  );
};

SupplierDetails.propTypes = {
  supplier: PropTypes.object.isRequired,
  psTags: PropTypes.array.isRequired,
  addToComparision: PropTypes.func,
  clearSupplier: PropTypes.func,
  isDashboard: PropTypes.bool,
  isSupplierPage: PropTypes.bool,
  contactSupplier: PropTypes.func,
};

export default SupplierDetails;
