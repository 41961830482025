import actionCreator from 'utils/actionCreator';

export const actions = actionCreator('app', [
  'SHOW_SNACKBAR',
  'HIDE_SNACKBAR',
  'UPDATE_COMPARE_SUPPLIERS',
  'TOGGLE_CONTACT_SUPPLIER',
]);

export function showSnackbar(payload) {
  return {
    type: actions.SHOW_SNACKBAR,
    payload,
  };
}

export function hideSnackbar() {
  return {
    type: actions.HIDE_SNACKBAR,
  };
}

export function updateCompareSuppliers(payload) {
  return {
    type: actions.UPDATE_COMPARE_SUPPLIERS,
    payload,
  };
}

export function toggleContactSupplier(payload) {
  return {
    type: actions.TOGGLE_CONTACT_SUPPLIER,
    payload,
  };
}
