import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import axios from 'axios';

import api from 'utils/api';

import {
  actions,
  searchRecordsSuccess,
  searchRecordsLoading,
  connectedSuppliersSuccess,
  supportRequestSuccess,
  supportRequestError,
} from './actions';
import { currentUser } from './selectors';

function* searchRecordsRequestSaga({ payload }) {
  yield put(searchRecordsLoading(true));

  try {
    const { data } = yield call(axios, {
      method: 'get',
      url: `${api.getSearchRecordsByUser}/${payload._id}`,
      headers: { 'x-auth-token': payload.x_auth_token },
    });

    yield put(searchRecordsSuccess(data.length === 0 ? ['none'] : data));
    yield put(searchRecordsLoading(false));
  } catch (errors) {
    yield put(searchRecordsLoading(false));
    // console.log(errors.response);
  }
}

export function* watchSearchRecordsRequest() {
  yield takeLatest(actions.SEARCH_RECORDS_REQUEST, searchRecordsRequestSaga);
}

function* connectedSuppliersSaga({ payload }) {
  try {
    const { data } = yield call(axios, {
      method: 'get',
      url: `${api.getConnectedSuppliers}/${payload._id}`,
      headers: { 'x-auth-token': payload.x_auth_token },
    });

    yield put(connectedSuppliersSuccess(data.length === 0 ? ['none'] : data));
  } catch (errors) {
    // console.log(errors.response);
  }
}

export function* watchConnectedSuppliers() {
  yield takeLatest(actions.CONNECTED_SUPPLIERS_REQUEST, connectedSuppliersSaga);
}

function* supportRequestSaga({ payload }) {
  const user = yield select(currentUser);
  const body = {
    name: payload.name || `${user.user_first_name} ${user.user_last_name}`,
    email: payload.email || user.user_email,
    message: payload.message,
  };

  if (payload.company && payload.company.length > 0)
    body.company = payload.company;
  if (payload.phone && payload.phone.length > 0) body.phone = payload.phone;
  if (payload.user_phone && payload.user_phone.length > 0)
    body.phone = payload.user_phone;

  yield put(supportRequestError(null));

  try {
    const response = yield call(axios.post, api.contactUs, body);

    if (response.data && Object.keys(response.data).length > 0) {
      yield put(supportRequestSuccess(true));
      yield delay(7000);
      yield put(supportRequestSuccess(false));
    }
    yield put(supportRequestError(null));
  } catch (errors) {
    yield put(supportRequestError(errors.response.data.error_msg));
  }
}

export function* watchSupportRequest() {
  yield takeLatest(actions.SUPPORT_REQUEST, supportRequestSaga);
}
