import React from 'react';
import PropTypes from 'prop-types';

import getImage from 'utils/getImage';

import { ServicesSection } from './SupplierDetails.styled';

const Services = ({ supplier, psTags }) => {
  function findImage(id) {
    const psTag = psTags.find((i) => i.ps_tags.some((j) => j === id));

    return `url('${getImage(
      psTag.image_id,
    )}') center/cover no-repeat, linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0.1) 49%, rgba(0, 0, 0, 0.65) 70%, rgba(0, 0, 0, 0.85) 88%, rgba(0, 0, 0, 0.9))`;
  }

  /* eslint-disable indent */

  return psTags &&
    psTags.length > 0 &&
    supplier.ps_offering &&
    supplier.ps_offering.length > 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <ServicesSection id="services">
      <h4>
        <span>Services</span>
      </h4>
      <div className="offerings">
        {supplier.ps_offering.map((i) => (
          <div key={i} style={{ background: findImage(i) }}>
            <span>{i}</span>
          </div>
        ))}
      </div>
    </ServicesSection>
  ) : (
    <></>
  );
  /* eslint-enable indent */
};

Services.propTypes = {
  supplier: PropTypes.object.isRequired,
  psTags: PropTypes.array.isRequired,
};

export default Services;
