import styled from 'styled-components';

export const Banner = styled.header`
  padding: 100px 0 200px;
  background-image: url('/assets/img/contact-us.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;

  h1 {
    color: #ffffff;
    font-weight: 500;
    font-size: 34px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
`;

export const FormSection = styled.section`
  background-color: ${({ theme: { colors } }) => colors.lightGrey};

  .contact-row {
    display: flex;
    width: 800px;
    background-color: #ffffff;
    margin: 0 auto;
    transform: translateY(-100px);
    min-height: 370px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

    @media (max-width: 979px) {
      flex-direction: column;
      width: 100%;
    }

    & > div {
      padding: 20px 15px;

      &:first-child {
        position: relative;
        flex: 1;
        background-color: ${({ theme: { colors } }) => colors.dullYellow};
        border-radius: 4px 0 0 4px;

        .details {
          list-style: none;
          padding: 0;
          margin: 0;

          @media (max-width: 979px) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          }

          @media (max-width: 750px) {
            flex-direction: column;
          }

          li {
            position: relative;
            padding: 0 0 0 30px;
            margin: 0 0 30px;
            font-size: 14px;

            svg {
              position: absolute;
              left: 5px;
              top: 4px;
              opacity: 0.35;
            }

            span {
              font-size: 12px;
              opacity: 0.7;

              &.bold {
                font-size: 14px;
                opacity: 1;
                font-weight: 500;
              }
            }

            a {
              color: ${({ theme: { colors } }) => colors.black};
            }
          }
        }

        .social {
          position: absolute;
          left: 15px;
          bottom: 15px;
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            padding: 0;
            margin: 0 16px 0 0;

            a {
              &:hover {
                opacity: 0.7;
              }

              svg {
                color: ${({ theme: { colors } }) => colors.black};
                fill: ${({ theme: { colors } }) => colors.gold};
              }
            }
          }
        }
      }

      &:last-child {
        flex: 2;

        form {
          margin-top: -8px;

          .input-row {
            display: flex;
            margin: 0 -8px;

            input {
              margin: 8px;
            }
          }

          textarea {
            margin-top: 8px;
            margin-bottom: 16px;
            height: 151px;
          }

          input,
          textarea {
            &.has-error {
              border-color: ${({ theme: { colors } }) => colors.red};
            }
          }
        }

        .errors {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin: 0;
            padding: 0;
            color: ${({ theme: { colors } }) => colors.red};
          }
        }

        .success-message {
          padding: 50px 0;

          p {
            max-width: 400px;
            margin: 30px auto;
          }
        }
      }
    }
  }
`;
